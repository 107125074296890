import React, { useState } from "react";
import "./style.scss";
import { Container, Button, Form } from "react-bootstrap";
//import { authHostAPI } from "../../../api/authHost-api";
import { Helmet } from "react-helmet";

import Rat from "../../../assets/images/triviarat-sign-up.png";
import { userHostApi } from "../../../api/user-api";

const UserResetPassword = () => {
	const [values, setValues] = useState({});
	const [wrongEmail, setWrongEmail] = useState(false);
	const [resetedPass, setResetedPass] = useState(false);
	const [serverError, setServerError] = useState("");

	const goBack = () => (window.location.pathname = "/");

	const resetPassword = (e) => {
		e.preventDefault();
		const baseUrl = window.location.origin;

		setWrongEmail(false);
		userHostApi
			.forgotPassword(values.email, baseUrl)
			.then(() => {
				setResetedPass(true);
			})
			.catch((err) => {
				if (err.response.status === 404) {
					setWrongEmail(true);
					return;
				}
				console.log("setting error", err);
				setServerError(
					err.response?.data?.message ||
						"Server Error Please try again later or contact support at support@triviarat.com"
				);
			});
	};

	const handleChange = (e) => {
		e.persist();
		setValues((values) => ({
			...values,
			[e.target.name]: e.target.value,
		}));
	};

	return (
		<div>
			<Helmet>
				<title>Reset Password - Trivia Rat</title>
			</Helmet>
			<div className="reset-password">
				<Container>
					<Form onSubmit={(e) => resetPassword(e)}>
						<div className="text-center">
							{/* <img className="rat-img" alt="Rat" src={Rat} /> */}
							<h1>Reset Password</h1>
						</div>
						<Form.Group>
							{resetedPass ? (
								<div className="alert alert-warning">
									An email has been sent to: <b>{values.email}</b>. Please check
									your inbox and follow the instructions provided. Thanks!
								</div>
							) : (
								<Form.Control
									onChange={handleChange}
									type="email"
									name="email"
									size="lg"
									control="input"
									placeholder="Enter email"
									autoFocus={true}
									pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
									required
								/>
							)}
							{wrongEmail ? (
								<p className="error">There is no user with such email.</p>
							) : null}
							{serverError ? <p className="error">{serverError}</p> : null}
						</Form.Group>
						{resetedPass ? null : (
							<Button type="submit" variant="success" size="lg">
								Reset
							</Button>
						)}
						<Button variant="secondary" className="mb-3" onClick={goBack}>
							Back
						</Button>
					</Form>
				</Container>
			</div>
		</div>
	);
};

export default UserResetPassword;
