import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { playerAPI } from "../../../../api/player-api";
import Slider from "react-slick";
import Select from "react-select";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { shuffle } from "../../../../util/shuffle";
import { v4 as uuidv4 } from "uuid";
import { comparePoints } from "../../../../util/compare";
import Loader from "../../../../pages/Admin/Game/Play/Loader";

const EnterResponse = ({
	role,
	roundCompleteMessage,
	setCustomTitle,
	gameInfo,
	currentRoundFromSocket,
	questionsFromSocket,
	playerAnswersFromSocket,
	imageMode,
	setImageMode,
	afterReconnect,
	setAfterReconnect,
	showQuestionsWS,
	setQuestionsFromSocket,
	playerId,
	playerScore,
	setAdsenseKey,
	lockedQuestion,
}) => {
	const [answer, setAnswer] = useState("");
	const [questions, setQuestions] = useState([]);
	const [questionNum, setQuestionNum] = useState(1);
	const [image, setImage] = useState("");
	const [answerIsSubmitting, setAnswerIsSubmitting] = useState(false);
	const [hideshow, setHideShow] = useState(false);
	const [questionLimit, setQuestionLimit] = useState(false);
	const [inProp, setInProp] = useState(false);
	const [questionsPerRound, setQuestionsPerRound] = useState(999);
	const [currentRound, setCurrentRound] = useState([]);
	const [showQuestions, setShowQuestions] = useState(false);
	const [wagerMode, setWagerMode] = useState(false);
	const [points, setPoints] = useState([]);
	const [usedPoints, setUsedPoints] = useState([]);
	const [pointsToBet, setPointsToBet] = useState([]);
	const [selectedPoint, setSelectedPoint] = useState(null);
	const [percentage, setPercentage] = useState(0);
	const [singleUse, setSingleUse] = useState(false);
	const [mandatoryWagering, setMandatoryWagering] = useState(false);
	const [wagerRoundType, setWagerRoundType] = useState("");
	const [responseError, setResponseError] = useState("");
	const [noOptionsMessage, setNoOptionsMessage] = useState("");
	const [hideAllOptions, setHideAllOptions] = useState(false);
	const [switchedToFirstEmptyAnswer, setSwitchedToFirstEmptyAnswer] =
		useState(true);
	const inputEl = useRef(null);

	const clearSettings = () => {
		setShowQuestions(false);
		setCurrentRound([]);
		setWagerMode(false);
		setWagerRoundType("");
		setSingleUse(false);
		setMandatoryWagering(false);
		setPoints([]);
		setPercentage(0);
		setQuestionsPerRound(999);

		setAnswer("");
		setQuestions([]);
		setQuestionNum(1);
		setImage("");
		setQuestionLimit(false);
		setUsedPoints([]);
		setPointsToBet([]);
		setSelectedPoint(null);
		setResponseError("");
		setNoOptionsMessage("");
		setHideAllOptions(false);
		setSwitchedToFirstEmptyAnswer(true);
		setAnswerSubmittingWithDelay(false);
	};

	// getting current round when mode changing, and setting questions and round length
	useEffect(() => {
		clearSettings();
		// default value which can be updated depending on different round settings
		let questionsLimitToSet = 999;
		// checking if informations comes from sockets,
		//  if no send request to get currentRound
		if (!afterReconnect && gameInfo.roundMode) {
			if (gameInfo.roundMode === "classic") {
				questionsLimitToSet = gameInfo.questionsPerRound;
				setImageMode(gameInfo.imageMode);
				setCustomTitle(gameInfo.customTitle);
			} else if (gameInfo.roundMode === "pre-entered") {
				questionsLimitToSet = currentRoundFromSocket.preEnteredQuestions.length;
				setImageMode(false);
				setShowQuestions(gameInfo.showQuestions);
				setCurrentRound(currentRoundFromSocket.preEnteredQuestions);
				setCustomTitle(currentRoundFromSocket.name);
			} else if (gameInfo.roundMode === "automatic") {
				questionsLimitToSet = currentRoundFromSocket.preEnteredQuestions.length;
				setImageMode(false);
				setShowQuestions(gameInfo.showQuestions);

				for (const round of currentRoundFromSocket.preEnteredQuestions) {
					round.variants = [
						{
							variant: round.answerText,
						},
					];
					for (const answer of round.incorrectAnswers) {
						let element = {
							variant: answer,
						};
						round.variants.push(element);
					}

					shuffle(round.variants);
				}

				setCurrentRound(currentRoundFromSocket.preEnteredQuestions);
				setCustomTitle(gameInfo.customTitle);
			}
			if (
				gameInfo.currentWagerScheme &&
				gameInfo.currentWagerScheme.type !== "standard"
			) {
				setWagerMode(true);
				setWagerRoundType(gameInfo.currentWagerScheme.type);
				setSingleUse(gameInfo.currentWagerScheme.singleUse);
				setMandatoryWagering(gameInfo.currentWagerScheme.mandatoryWagering);
				if (gameInfo.currentWagerScheme.type === "points") {
					setPoints(gameInfo.currentWagerScheme.points.sort(comparePoints));
					if (
						gameInfo.currentWagerScheme.mandatoryWagering &&
						gameInfo.currentWagerScheme.singleUse &&
						gameInfo.currentWagerScheme.points.length < questionsLimitToSet
					) {
						questionsLimitToSet = gameInfo.currentWagerScheme.points.length;
					}
				} else {
					setPercentage(gameInfo.currentWagerScheme.percentage);
					if (
						gameInfo.currentWagerScheme.mandatoryWagering &&
						gameInfo.currentWagerScheme.singleUse
					) {
						questionsLimitToSet = 1;
					}
				}
			} else {
				setWagerMode(false);
			}
			setQuestionsPerRound(questionsLimitToSet);
		} else {
			playerAPI
				.getCurrentRound()
				.then((res) => {
					if (res.game.roundMode === "classic") {
						questionsLimitToSet = res.game.questionsPerRound;
						setImageMode(res.game.imageMode);
						setCustomTitle(res.game.customTitle);
					} else if (res.game.roundMode === "pre-entered") {
						questionsLimitToSet =
							res.game.currentRound.preEnteredQuestions.length;
						setImageMode(false);
						setShowQuestions(res.game.showQuestions);
						setCurrentRound(res.game.currentRound.preEnteredQuestions);
						setCustomTitle(res.game.currentRound.name);
					} else if (res.game.roundMode === "automatic") {
						questionsLimitToSet =
							res.game.currentRound.preEnteredQuestions.length;
						setImageMode(false);
						setShowQuestions(res.game.showQuestions);

						for (const round of res.game.currentRound.preEnteredQuestions) {
							round.variants = [
								{
									variant: round.answerText,
								},
							];
							for (const answer of round.incorrectAnswers) {
								let element = {
									variant: answer,
								};
								round.variants.push(element);
							}
							shuffle(round.variants);
						}

						setCurrentRound(res.game.currentRound.preEnteredQuestions);
						setCustomTitle(res.game.customTitle);
					}
					if (
						res.game.currentWagerScheme &&
						res.game.currentWagerScheme.type !== "standard"
					) {
						setWagerMode(true);
						setWagerRoundType(res.game.currentWagerScheme.type);
						setSingleUse(res.game.currentWagerScheme.singleUse);
						setMandatoryWagering(res.game.currentWagerScheme.mandatoryWagering);
						if (res.game.currentWagerScheme.type === "points") {
							setPoints(res.game.currentWagerScheme.points.sort(comparePoints));
							if (
								res.game.currentWagerScheme.mandatoryWagering &&
								res.game.currentWagerScheme.singleUse &&
								res.game.currentWagerScheme.points.length < questionsLimitToSet
							) {
								questionsLimitToSet = res.game.currentWagerScheme.points.length;
							}
						} else {
							setPercentage(res.game.currentWagerScheme.percentage);
							if (
								res.game.currentWagerScheme.mandatoryWagering &&
								res.game.currentWagerScheme.singleUse
							) {
								questionsLimitToSet = 1;
							}
						}
					} else {
						setWagerMode(false);
					}
					setQuestionsPerRound(questionsLimitToSet);
				})
				.catch((err) => {
					alert(err);
				});
		}
	}, [
		setCustomTitle,
		gameInfo,
		currentRoundFromSocket,
		afterReconnect,
		setImageMode,
	]);

	//add delay to setAnswerIsSubmitting so when they can't skip it shows loading for a blip
	const setAnswerSubmittingWithDelay = (value) => {
		if (value === false) {
			setTimeout(() => setAnswerIsSubmitting(false), 1000); // 500 ms delay (adjust as needed)
		} else {
			setAnswerIsSubmitting(value);
		}
	};

	//*computed property called currentQuestion

	const currentQuestion = questions[questionNum - 1] || {};

	// setting to select previous betted point
	const settingSelectedPoint = useCallback(
		(betPoints) => {
			if (betPoints) {
				setSelectedPoint({
					value: uuidv4(),
					label: betPoints,
					pointValue: betPoints,
				});
			} else {
				setSelectedPoint(pointsToBet[0]);
			}
		},
		[pointsToBet]
	);

	const createOptionsForSelect = useCallback(
		(arrayToSet) => {
			let optionsToSet = [];
			optionsToSet.push({
				value: uuidv4(),
				label: mandatoryWagering ? "Select Wager" : "No Wager",
				pointValue: 0,
			});
			for (let i = 0; i <= arrayToSet.length - 1; i++) {
				optionsToSet.push({
					value: uuidv4(),
					label: arrayToSet[i],
					pointValue: arrayToSet[i],
				});
			}

			return optionsToSet;
		},
		[mandatoryWagering]
	);

	// setting options for select of points to bet
	useEffect(() => {
		if (wagerMode && !!points.length) {
			let pointsToCreate = points;

			if (singleUse) {
				pointsToCreate = points.filter((point) => !usedPoints.includes(point));
			}

			const optionsToSet = createOptionsForSelect(pointsToCreate);
			setPointsToBet(optionsToSet);
		} else if (wagerMode && percentage) {
			const pointsToCreate = [];

			if (!usedPoints.length) {
				for (let i = 1; i <= (playerScore / 100) * percentage; i++) {
					pointsToCreate.push(i);
				}
			}
			const optionsToSet = createOptionsForSelect(pointsToCreate);
			setPointsToBet(optionsToSet);
		}
	}, [
		wagerMode,
		percentage,
		playerScore,
		points,
		usedPoints,
		singleUse,
		createOptionsForSelect,
	]);

	// setting selectedPoint for first answer if we have it
	useEffect(() => {
		if (!!questions.length && !selectedPoint && !switchedToFirstEmptyAnswer) {
			settingSelectedPoint(questions[0].bet);
		}
	}, [
		questions,
		selectedPoint,
		switchedToFirstEmptyAnswer,
		settingSelectedPoint,
	]);

	useEffect(() => {
		//console.log({ lockedQuestion });
		if (lockedQuestion < questionNum) {
			handleSetQuestionNum(lockedQuestion);
		}
	}, [lockedQuestion]);

	const handleSetQuestionNum = (orderNum) => {
		if (lockedQuestion < orderNum) {
			orderNum = lockedQuestion;
		}
		setQuestionNum(orderNum);
		if (questions.length && questions[orderNum - 1].answer) {
			setAnswer(questions[orderNum - 1].answer);
		} else if (!!answer.length) {
			setAnswer("");
		}
		if (!!responseError.length) {
			setResponseError("");
		}
	};

	// finding first empty question and switching player to it
	useEffect(() => {
		if (questions.length && !switchedToFirstEmptyAnswer && pointsToBet.length) {
			for (const question of questions) {
				if (!question.answer) {
					handleSetQuestionNum(question.orderNum);
					settingSelectedPoint(question.bet);
					setSwitchedToFirstEmptyAnswer(true);
					break;
				}
			}
		} else if (questions.length && switchedToFirstEmptyAnswer) {
			let switched = false;
			for (const question of questions) {
				if (!question.answer) {
					handleSetQuestionNum(question.orderNum);
					settingSelectedPoint(question.bet);
					setSwitchedToFirstEmptyAnswer(true);
					switched = true;
					break;
				}
			}
			if (!switched) {
				setAnswer(questions[0].answer);
				setQuestionLimit(true);
			}
		}
	}, [
		questions,
		pointsToBet,
		switchedToFirstEmptyAnswer,
		settingSelectedPoint,
	]);

	// focus on input after answer send
	useEffect(() => {
		if (!answerIsSubmitting && inputEl.current) {
			inputEl.current.focus();
		}
	}, [questionNum, answerIsSubmitting]);

	// setting QuestionLimit UI block
	useEffect(() => {
		if (questionNum > questionsPerRound) {
			setQuestionLimit(true);
		} else {
			setQuestionLimit(false);
		}
	}, [questionNum, questionsPerRound]);

	// switch questionKey to rerender AdSense component when user change anser
	useEffect(() => {
		setAdsenseKey(questionNum + 1);
	}, [questionNum, setAdsenseKey]);

	// settingShowQuestions from socket when host change it
	useEffect(() => {
		if (showQuestionsWS !== undefined) {
			setShowQuestions(showQuestionsWS);
		}
	}, [showQuestionsWS]);

	// getting array of questions to map buttons for each question
	useEffect(() => {
		if (afterReconnect || questions.length < 1) {
			const questionToSet = [];
			if (questionsFromSocket) {
				// set questions
				for (const question of questionsFromSocket) {
					questionToSet.push(question);
				}
				const usedPointsToSet = [];
				for (const question of questionToSet) {
					for (const answer of playerAnswersFromSocket) {
						if (question.orderNum === answer.orderNum) {
							question.answer = answer.text;
							question.marked = answer.marked;
							question._id = answer._id;
						}
						// checking for previous player bets and setting it to his answer
						for (const wager of answer.wagers) {
							if (
								wager.player === playerId &&
								question.orderNum === answer.orderNum
							) {
								question.bet = wager.bet;
								usedPointsToSet.push(wager.bet);
							}
						}
					}
				}
				setUsedPoints(usedPointsToSet);
				setQuestions(questionToSet);
				setQuestionsFromSocket(undefined);
			} else {
				playerAPI
					.getQuestions()
					.then((res) => {
						for (const question of res.questions) {
							questionToSet.push(question);
						}
					})
					.then(() => {
						playerAPI.getQuestion(questionNum).then((res) => {
							if (!questionToSet[0]) {
								const questionParams = {
									isLast: true,
									_id: res.questionId,
									orderNum: res.orderNum,
								};
								questionToSet.push(questionParams);
							}
							playerAPI
								.getAnswers()
								.then((res) => {
									const usedPointsToSet = [];
									for (const question of questionToSet) {
										for (const answer of res.answers) {
											if (question.orderNum === answer.orderNum) {
												question.answer = answer.text;
												question.marked = answer.marked;
												question._id = answer._id;
											}
											// checking for previous player bets and setting it to his answer
											for (const wager of answer.wagers) {
												if (
													wager.player === playerId &&
													question.orderNum === answer.orderNum
												) {
													question.bet = wager.bet;
													usedPointsToSet.push(wager.bet);
												}
											}
										}
									}

									setUsedPoints(usedPointsToSet);
									setQuestions(questionToSet);
								})
								.catch((err) => {
									alert(err);
								});
						});
					});
			}
			setAfterReconnect(false);
		}
	}, [
		questionNum,
		questions,
		playerId,
		afterReconnect,
		questionsFromSocket,
		playerAnswersFromSocket,
		setAfterReconnect,
		setQuestionsFromSocket,
	]);

	useEffect(() => {
		if (!inputEl.current) return;
		inputEl.current.style.height = "0px";
		const scrollHeight = inputEl.current.scrollHeight;
		inputEl.current.style.height = scrollHeight + "px";
	}, [answer]);

	// setting switchBack button and send request to get image
	useEffect(() => {
		if (questionNum === 1) {
			setHideShow(false);
		} else {
			setHideShow(true);
		}
		setImage("");
		if (imageMode) {
			playerAPI
				.getImage(questionNum)
				.then((res) => {
					setImage(res.imageUrl);
				})
				.catch((err) => {
					setImage("");
				});
		} else {
			setImage("");
		}
	}, [questionNum, imageMode]);

	const sendAnswer = () => {
		setResponseError("");
		setQuestionLimit(false);
		setAnswerSubmittingWithDelay(true);
		if (questionNum === 1 && !questions[questionNum - 1]) {
			// create first question if no questions in array
			playerAPI.getQuestion(questionNum);
		}

		if (
			wagerMode &&
			singleUse &&
			mandatoryWagering &&
			wagerRoundType === "percentage" &&
			(playerScore / 100) * percentage < selectedPoint.pointValue
		) {
			setSelectedPoint(pointsToBet[0]);
			setResponseError("You don't have enough points to bet");
			setAnswerSubmittingWithDelay(false);
			return;
		}

		if (answer.trim() === "" && singleUse && mandatoryWagering) {
			setResponseError("You can't skip questions!");
			setAnswerSubmittingWithDelay(false);
			return;
		}

		if (
			answer.trim() !== "" &&
			mandatoryWagering &&
			!selectedPoint.pointValue
		) {
			setResponseError("You required to place a bet!");
			setAnswerSubmittingWithDelay(false);
			return;
		}

		if (
			questions[questionNum - 1].marked &&
			questions[questionNum - 1].answer !== answer.trim().toUpperCase()
		) {
			setResponseError("You cant change marked answer!");
			setAnswer(questions[questionNum - 1].answer);
			setAnswerSubmittingWithDelay(false);
			return;
		}

		if (answer.trim() !== "" && questionNum <= questionsPerRound) {
			// send answer if answer exist
			playerAPI
				.submitAnswer(
					answer.trim().toUpperCase(),
					questionNum,
					selectedPoint && selectedPoint.pointValue !== 0
						? selectedPoint.pointValue
						: undefined
				)
				.then((res) => {
					const questionsCopy = questions;

					for (const bet of res.data.answer.wagers) {
						if (bet.player === playerId) {
							questionsCopy[questionNum - 1].bet = bet.bet;
							break;
						}
					}

					questionsCopy[questionNum - 1].answer = res.data.answer.text;
					setQuestions(questionsCopy);

					let pointsToSet = pointsToBet;
					if (
						wagerRoundType === "points" &&
						singleUse &&
						selectedPoint &&
						selectedPoint.pointValue !== 0
					) {
						pointsToSet = pointsToBet.filter(
							(point) => point.value !== selectedPoint.value
						);
					} else if (
						wagerRoundType === "percentage" &&
						selectedPoint &&
						selectedPoint.pointValue !== 0
					) {
						pointsToSet = pointsToBet.slice(0, 1);
					}

					setPointsToBet(pointsToSet);
				})
				.then(() => {
					if (questionNum >= questionsPerRound) {
						setQuestionLimit(true);
					}
					// if creating new question
					else if (questionNum < questionsPerRound + 1) {
						// get next question and answer to it if it is allowed by host
						playerAPI
							.getQuestion(questionNum + 1)
							.then((res) => {
								if (res.orderNum <= questionsPerRound) {
									const questionsCopy = questions;
									if (questions.length < res.orderNum) {
										// if we create new question - update state with questions

										questionsCopy[questionsCopy.length - 1].isLast = false;
										const questionParams = {
											isLast: true,
											_id: res.questionId,
											orderNum: res.orderNum,
										};
										questionsCopy.push(questionParams);
									}
									setQuestions(questionsCopy);
									setImage("");
									settingSelectedPoint(questions[questionNum].bet);
									handleSetQuestionNum(questionNum + 1);
								} else {
									setQuestionLimit(true);
								}
								setAnswerSubmittingWithDelay(false);
							})
							.catch((err) => {
								setAnswerSubmittingWithDelay(false);
								if (questionNum === questionsPerRound) {
									setQuestionLimit(true);
								} else if (err.response.status !== 431) {
									alert(err);
								}
							});
					}
				})
				.catch((err) => {
					if (err.response.status === 431) {
						alert("You was deleted from game");
						localStorage.removeItem("user");
						window.location.pathname = "/";
					} else {
						setResponseError(err.response.data.message);
						setAnswerSubmittingWithDelay(false);
					}
				});
		} else {
			// we skip previous question and move to next one here
			if (questionNum < questionsPerRound + 1) {
				// get next question and answer to it if it is allowed by host
				playerAPI
					.getQuestion(questionNum + 1)
					.then((res) => {
						if (res.orderNum <= questionsPerRound) {
							const questionsCopy = questions;
							if (questions.length < res.orderNum) {
								// if we create new question - update state with questions

								questionsCopy[questionsCopy.length - 1].isLast = false;
								const questionParams = {
									isLast: true,
									_id: res.questionId,
									orderNum: res.orderNum,
								};
								questionsCopy.push(questionParams);
							}
							setQuestions(questionsCopy);
							setImage("");
							settingSelectedPoint(questions[questionNum].bet);
							handleSetQuestionNum(questionNum + 1);
						} else {
							setQuestionLimit(true);
						}
					})
					.catch((err) => {
						if (questionNum === questionsPerRound) {
							setQuestionLimit(true);
						} else if (err.response.status !== 431) {
							alert(err);
						}
					});
				setAnswerSubmittingWithDelay(false);
			}
		}
	};

	// setting answer from input
	const handleInput = (event) => {
		setAnswer(event.target.value);
	};

	// switching back and setting answer for prev question
	const switchBack = () => {
		setImage("");
		settingSelectedPoint(questions[questionNum - 2].bet);
		handleSetQuestionNum(questionNum - 1);
	};

	// switching to another question and getting of answer for selected question
	const switchQuestion = (question) => {
		setInProp(true);
		setQuestionLimit(false);
		setAnswerSubmittingWithDelay(false);
		if (question.orderNum > questionsPerRound) {
			setQuestionLimit(true);
		} else {
			// swtching to different question
			if (question.orderNum !== questionNum) {
				setImage("");
				settingSelectedPoint(questions[question.orderNum - 1].bet);
				handleSetQuestionNum(question.orderNum);
				setInProp(false);
			}
		}
	};

	const hideAllOptionsHandler = (message) => {
		setHideAllOptions(true);
		setNoOptionsMessage(message);
	};

	// when opening a selct dropdown check what we should show to player
	const onMenuOpen = () => {
		//console.log("BET:: ", questions[questionNum - 1]?.bet ?? 0);
		if (questions[questionNum - 1]?.bet) {
			hideAllOptionsHandler("You cant change your bet");
			return;
		}
		if (
			wagerRoundType === "percentage" &&
			(playerScore / 100) * percentage < 1
		) {
			setSelectedPoint(pointsToBet[0]);
			hideAllOptionsHandler("You don't have enough points to bet");
			return;
		}
		if (pointsToBet.length === 1) {
			hideAllOptionsHandler("You already use all points");
			return;
		}

		setHideAllOptions(false);
	};

	const settings = {
		className: "center",
		arrows: false,
		infinite: false,
		centerPadding: "60px",
		slidesToShow: 5.4,
		swipeToSlide: true,
	};

	return (
		<CSSTransition in={inProp} timeout={500} classNames="my-node">
			<div className="response varitykiller">
				{questionLimit ? (
					<div className="question-limit">
						<h1 className="tiger">{roundCompleteMessage}</h1>
						<FontAwesomeIcon icon="flag-checkered" className="fa-5x mt-4" />
					</div>
				) : (
					<>
						<div className="d-flex flex-column align-items-center">
							{(showQuestions || role === "auth") && currentRound.length > 0 ? (
								<>
									<div className="pre-entered">
										<h2 className="question-number">
											#
											<span className="display-4 varitykiller">
												{questionNum}
											</span>
											<br />
										</h2>
										{questionNum <= currentRound.length ? (
											<>
												<h5 className="ml-1 text-center px-2">
													{currentRound[questionNum - 1].questionText}
												</h5>
											</>
										) : null}
									</div>
									{questionNum <= currentRound.length &&
									currentRound[questionNum - 1].variants &&
									currentRound[questionNum - 1].variants.length > 1 ? (
										<div className="variants">
											{currentRound[questionNum - 1].variants.map(
												(variant, i, array) => {
													return i === array.length - 1 ? (
														<span key={i}>{variant.variant}</span>
													) : (
														<span key={i}>
															{variant.variant} {" - "}
														</span>
													);
												}
											)}
										</div>
									) : null}
									{questionNum <= currentRound.length &&
									currentRound[questionNum - 1].image &&
									currentRound[questionNum - 1].image.length > 0 ? (
										<img
											alt="Question"
											src={currentRound[questionNum - 1].image}
											className="question-image"
										/>
									) : null}
								</>
							) : (
								<h2 className="question-number">
									#<span className="display-3 varitykiller">{questionNum}</span>
									<br />
								</h2>
							)}

							{image.length > 0 ? (
								<img alt="Question" src={image} className="question-image" />
							) : null}
							<p className="heebo subtitle text-xs mb-3">Enter response:</p>
						</div>
						<Form
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									sendAnswer();
								}
							}}
						>
							<Form.Group>
								<Form.Control
									type="text"
									value={answer}
									as="textarea"
									className-data="player-input unsubmitted-border"
									className={`player-input ${
										currentQuestion.answer
											? "submitted-border"
											: "unsubmitted-border"
									}`}
									onChange={handleInput}
									autoComplete="off"
									disabled={answerIsSubmitting}
									ref={inputEl}
									onFocus={(e) => {
										let value = e.target.value;
										e.target.value = "";
										e.target.value = value;
									}}
								/>

								{answerIsSubmitting ? (
									<div className="centered my-2">
										<Loader />
									</div>
								) : null}

								{wagerMode && (
									<>
										<p className="heebo text-center bet">Bet:</p>
										<Select
											key={`unique_key_${
												selectedPoint ? selectedPoint.value : 0
											}`}
											maxMenuHeight={180}
											value={selectedPoint}
											className="select"
											isSearchable={false}
											onMenuOpen={onMenuOpen}
											onChange={setSelectedPoint}
											options={hideAllOptions ? [] : pointsToBet}
											noOptionsMessage={() => noOptionsMessage}
											isDisabled={!questions.length}
										/>
										{responseError && (
											<p className="error heebo text-center">{responseError}</p>
										)}
									</>
								)}
							</Form.Group>

							<div className="w-100 d-flex justify-content-center">
								<Button
									variant="secondary"
									className={hideshow ? "d-block" : "d-none"}
									onClick={switchBack}
								>
									<FontAwesomeIcon icon="arrow-alt-circle-left" />
								</Button>
								{lockedQuestion !== questionNum ? (
									<Button
										variant="primary"
										onClick={sendAnswer}
										disabled={
											answerIsSubmitting || lockedQuestion < questionNum
										}
										className="ml-2"
									>
										submit
										<FontAwesomeIcon
											icon="arrow-alt-circle-right"
											className="ml-1"
										/>
									</Button>
								) : (
									<Button
										variant="primary"
										onClick={sendAnswer}
										disabled={
											answerIsSubmitting || lockedQuestion < questionNum
										}
										className="ml-2"
									>
										submit
									</Button>
								)}
							</div>
						</Form>
					</>
				)}

				<br />
				<div className="d-flex flex-column align-items-center">
					<span className="heebo jump-to">Jump To:</span>
					<div className="buttons-scroller ">
						<Slider {...settings}>
							{questions.map((question) => {
								if (lockedQuestion < question.orderNum) {
									return;
								} else if (+questionNum === question.orderNum) {
									return (
										<Button
											key={question._id}
											className="active"
											onClick={() => switchQuestion(question)}
											variant="primary"
											disabled={question.orderNum > lockedQuestion}
										>
											{question.orderNum}
										</Button>
									);
								} else if (!question.answer) {
									return (
										<Button
											key={question._id}
											onClick={() => switchQuestion(question)}
											variant="outline-primary"
											disabled={question.orderNum > lockedQuestion}
										>
											{question.orderNum}
										</Button>
									);
								} else {
									return (
										<Button
											key={question._id}
											onClick={() => switchQuestion(question)}
											variant="primary"
											disabled={question.orderNum > lockedQuestion}
										>
											{question.orderNum}
										</Button>
									);
								}
							})}
						</Slider>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default EnterResponse;
