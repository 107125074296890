import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPlayCircle,
	faPlay,
	faCheckSquare,
	faQuestionCircle,
	faStopCircle,
	faMedal,
	faTrashAlt,
	faArrowRight,
	faArrowCircleRight,
	faArrowLeft,
	faVideo,
	faShareSquare,
	faCouch,
	faEdit,
	faBomb,
	faArrowAltCircleRight,
	faArrowAltCircleLeft,
	fas,
	faPen,
	faPlusSquare,
	faImages,
	faBath,
	faTrophy,
	faLandmark,
	faBed,
	faQrcode,
	faWindowClose,
	faMarker,
	faBolt,
	faFlagCheckered,
	faCheck,
	faPencilAlt,
	faTimes,
	faBars,
	faFastBackward,
	faStepBackward,
	faStepForward,
	faFastForward,
	faCog,
	faAngleUp,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faHouseUser,
	faPlug,
} from "@fortawesome/free-solid-svg-icons";
import {
	fab,
	faCreativeCommonsZero,
	faFacebook,
	faTwitch,
} from "@fortawesome/free-brands-svg-icons";
import {
	faEye,
	faEyeSlash,
	faHandPaper,
	far,
} from "@fortawesome/free-regular-svg-icons";

library.add(
	faCreativeCommonsZero,
	faArrowAltCircleRight,
	faArrowAltCircleLeft,
	faArrowCircleRight,
	faQuestionCircle,
	faFlagCheckered,
	faFastBackward,
	faStepBackward,
	faFastForward,
	faStepForward,
	faWindowClose,
	faCheckSquare,
	faShareSquare,
	faArrowRight,
	faPlayCircle,
	faPlay,
	faStopCircle,
	faPlayCircle,
	faPlusSquare,
	faAngleRight,
	faHandPaper,
	faArrowLeft,
	faPencilAlt,
	faAngleDown,
	faAngleLeft,
	faTrashAlt,
	faFacebook,
	faLandmark,
	faEyeSlash,
	faAngleUp,
	faMarker,
	faQrcode,
	faTwitch,
	faTrophy,
	faImages,
	faTimes,
	faMedal,
	faCheck,
	faCouch,
	faVideo,
	faBolt,
	faBars,
	faBomb,
	faBath,
	faEdit,
	faBed,
	faPen,
	faEye,
	faCog,
	fab,
	far,
	fas,
	faHouseUser,
	faPlug
);
