import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import { useHistory, useParams } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import { userHostApi } from "../../../api/user-api";

import { Helmet } from "react-helmet";

import Rat from "../../../assets/images/triviarat-sign-up.png";
import "../../formStyles.scss";
import "./style.scss";
import "../../../components/FontAwesomeIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useQuery from "../../../util/getQuery";

const VerifyMail = () => {
	const history = useHistory();
	const { verifyToken } = useParams();
	const query = useQuery();
	const room = query.get("room");

	const [success, setSuccess] = useState(null);

	useEffect(() => {
		userHostApi
			.verifyAccount(verifyToken)
			.then((res) => {
				setSuccess(true);
				localStorage.setItem(
					"user",
					JSON.stringify({
						role: res.role,
						auth: res.token,
					})
				);
			})
			.catch((err) => {
				setSuccess(false);
			});
	}, []);

	return (
		<div className="resend-verification">
			<Helmet>
				<title>Resend Verification</title>
			</Helmet>
			<Form className="form">
				{window.APP_IS_WHITE_LABEL ? (
					<div className="d-flex justify-content-center mb-4">
						<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLVddAc5uo-e3pZIoIjCpxr1LYee5zXR0Zs6NtrOFibJxuem2uGSQg3Rt82KZiHWLqzw&usqp=CAU" />
					</div>
				) : null}

				<h3 className="resend-heading">Account Verified!</h3>

				{success && (
					<>
						<div className="icon-div">
							<FontAwesomeIcon icon={["fas", "check"]} className="icon-check" />
						</div>
						<p className="resend-subtitle">Your email has been verified.</p>
						<div className="d-flex justify-content-center">
							{/* <Button
								className="tiger  home-button"
								onClick={() => history.push(room ? `/${room}` : "/")}
								variant="secondary"
							>
								Join
							</Button> */}
							<a href={room ? `/${room}` : "/"}>
								<Button className="tiger  home-button" variant="secondary">
									Join
								</Button>
							</a>
						</div>
					</>
				)}

				{success === false && (
					<>
						<div className="icon-div">
							<FontAwesomeIcon icon={["fas", "check"]} className="icon-cross" />
						</div>

						<p className="resend-subtitle">Verification failed.</p>
						<Button
							className="tiger my-4 resend-verify-up-btn"
							block
							type="submit"
							variant="success"
							onClick={() => history.push("/user/resend-verification")}
						>
							Resend Verification Email
						</Button>
						<div className="d-flex justify-content-center">
							<Button
								className="tiger home-button"
								onClick={() => history.push("/")}
								variant="secondary"
							>
								Home
							</Button>
						</div>
					</>
				)}
			</Form>
		</div>
	);
};

export default VerifyMail;
