import React, { useState } from "react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { contactApi } from "../../api/contact-api";

const ContactUs = () => {
	const [values, setValues] = useState({});
	const [sendedFeedback, setSendedFeedback] = useState(false);

	const goBack = () => window.history.back();

	const handleChange = (e) => {
		e.persist();
		setValues((values) => ({
			...values,
			[e.target.name]: e.target.value,
		}));
	};

	const sendFeedback = (e) => {
		e.preventDefault();
		contactApi
			.sendFeedback(values.email, values.message)
			.then((res) => {
				setSendedFeedback(true);
			})
			.catch((err) => {
				alert(err);
			});
	};
	return (
		<div className="contact-page">
			<Helmet>
				<title>Trivia Rat</title>
			</Helmet>
			<Container>
				<br />
				<Row>
					<Col className="d-flex flex-column align-items-center">
						<h2 className="display-4 text-center">Contact Us</h2>
						<br />
						{sendedFeedback ? (
							<div className="alert alert-secondary">
								Message sent! Thanks for contacting us!
							</div>
						) : (
							<>
								<div className="alert alert-secondary">
									We'd love to hear any positive or negative feedback you may
									have...especially negative feedback, we love that stuff.
								</div>
								<Form className="feedback-form" onSubmit={sendFeedback}>
									<Form.Group>
										<Form.Label>Email address</Form.Label>
										<Form.Control
											onChange={handleChange}
											type="email"
											name="email"
											control="input"
											value={values.email || ""}
											placeholder="Enter email"
											pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
											required
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label>Comments</Form.Label>
										<Form.Control
											as="textarea"
											rows="3"
											onChange={handleChange}
											type="text"
											name="message"
											control="input"
											value={values.message || ""}
											required
										/>
									</Form.Group>
									<Button block type="submit" variant="success">
										Submit
									</Button>
								</Form>
								<br />
								<a href="mailto:contact@triviarat.com">
									<div className="alert alert-secondary">
										Or email: contact@triviarat.com
									</div>
								</a>
								<br />
							</>
						)}
						<Button variant="secondary" className="mb-3" onClick={goBack}>
							Home
						</Button>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ContactUs;
