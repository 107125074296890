import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import "./style.scss";

const ChangeRoomModal = ({ show, handleClose }) => {
  const [roomCode, setRoomCode] = useState(String);
  const input = useRef();

  useEffect(() => input.current && input.current.focus());

  const handleChange = (e) => {
    setRoomCode(e.target.value.split(" ").join(""));
  };

  const play = (e) => {
    e.preventDefault();
    window.location.pathname = roomCode;
  };
  return (
    <Modal
      className="change-room"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Header closeButton>
        <h5 className="slug-and-lion">
          Enter the room code the host has provided you
        </h5>
      </Modal.Header>
      <Form onSubmit={play}>
        <Modal.Body>
          <Form.Group controlId="playerLogin">
            <Form.Control
              type="text"
              autoComplete="off"
              name="room-code"
              ref={input}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            OK
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangeRoomModal;
