import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import UserResetPassword from "../pages/User/ForgotPassword/ResetPassword";
import Host from "../pages/Admin/Host";
import { observer } from "mobx-react";
import { RoleStoreContext } from "../store/RoleStrore";
import ContactUs from "../pages/ContactUs/ContactUs";
import ChangingPassword from "../pages/ResetPassword/ChangingPassword";
import UserChangingPassword from "../pages/User/ForgotPassword/ChangingPassword";
import PrivateRoute from "./Routes/PrivatRoute";
import PublicRoute from "./Routes/PublicRoute";
import Player from "../pages/Room/Player/Player";
import PlayerRoute from "./Routes/PlayerRoute";
import RoomRoute from "./Routes/RoomRoute";
import ScrollToTop from "../util/ScrollToTop";
import SignUp from "../pages/SignUp/SignUp";
import UserSignIn from "../pages/User/SignIn/SignIn";
import UserSignUp from "../pages/User/SignUp/SignUp";
import VenueSignUp from "../pages/Venue/VenueSignUp";
import TermsAndConditions from "../pages/Contracts/Terms";
import Privacy from "../pages/Contracts/Privacy";
import SignIn from "../pages/SignIn/SignIn";
import TwitchScoreTable from "../pages/TwitchScoreTable/TwitchScoreTable";
import { Helmet } from "react-helmet";
import ResendVerifyMail from "../pages/User/VerifyEmail/ResendVerifyMail";
import VerifyMail from "../pages/User/VerifyEmail/VerifyMail";

const Router = observer(() => {
	const roleStore = useContext(RoleStoreContext);
	const user = JSON.parse(localStorage.getItem("user"));
	let isFirst;
	if (user) {
		roleStore.role = user.role;
		if (
			roleStore.role === "auth" ||
			roleStore.role === "guest" ||
			roleStore.role === "pro"
		) {
			isFirst = user.firstTime;
		}
	}

	const domain = window.location.hostname;
	let pageTitle = "Trivia Player Portal";

	if (domain.includes("sunset")) {
		pageTitle = "Sunset Trivia Player Portal";
	}

	return (
		<>
			<ScrollToTop />
			<Helmet>
				<title>{pageTitle}</title>
			</Helmet>
			<Switch>
				<Route exact path="/contact-us" component={ContactUs} />

				<Route exact path="/sign-up" component={SignUp} />
				<Route exact path="/venue-sign-up" component={VenueSignUp} />

				<Route exact path="/sign-in" component={SignIn} />

				<Route exact path="/user/sign-up" component={UserSignUp} />

				<Route exact path="/user/sign-in" component={UserSignIn} />
				<Route exact path="/privacy" component={Privacy} />
				<Route
					exact
					path="/terms-and-conditions"
					component={TermsAndConditions}
				/>

				<Route
					exact
					path="/user/resend-verification"
					component={ResendVerifyMail}
				/>

				<Route
					exact
					path="/user/verify-account/:verifyToken"
					component={VerifyMail}
				/>

				<Route
					exact
					path="/scoreboard/:key"
					user={roleStore}
					component={TwitchScoreTable}
				/>

				<PublicRoute
					exact
					path="/reset-password"
					user={roleStore}
					component={ResetPassword}
				/>

				<PublicRoute
					exact
					path="/user/reset-password"
					user={roleStore}
					component={UserResetPassword}
				/>

				<PublicRoute
					exact
					path="/reset-password/:resToken"
					user={roleStore}
					component={ChangingPassword}
				/>

				<PublicRoute
					exact
					path="/user/reset-password/:resToken"
					user={roleStore}
					component={UserChangingPassword}
				/>

				<PrivateRoute
					path="/host"
					user={roleStore}
					component={() => <Host isFirst={isFirst} />}
				/>

				<PlayerRoute exac path="/player" user={roleStore} component={Player} />

				<RoomRoute path="/:roomCode" user={roleStore} />

				<PublicRoute path="/" />
			</Switch>
		</>
	);
});

export default Router;
