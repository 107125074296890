import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import QueryString from "qs";
import { Link, useHistory, useLocation } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import { userHostApi } from "../../../api/user-api";
import useForm from "../../../util/authForm/useForm";
import validate from "../../../util/authForm/validate";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Rat from "../../../assets/images/triviarat-sign-up.png";
import "../../formStyles.scss";
import "./style.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import useQuery from "../../../util/getQuery";

const initialValues = {
	email: "",
};

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.trim()
		.min(4, "Minimum 4 symbols")
		.required("Email is required"),
});

const ResendVerifyMail = () => {
	const history = useHistory();
	const query = useQuery();
	const room = query.get("room");
	const [mailSend, setMailSent] = useState(false);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, { setFieldError, setSubmitting }) => {
			setSubmitting(true);
			const baseURL = window.location.origin;
			userHostApi
				.resendVerification(values.email.toLowerCase(), room, baseURL)
				.then((res) => {
					setMailSent(true);
				})
				.catch((err) => {
					if (err.response && err.response.status === 404) {
						setFieldError("email", err.response?.data?.message);
					} else {
						alert(err);
					}
				});
		},
	});

	return (
		<div className="resend-verification">
			<Helmet>
				<title>TriviaRat.com - Resend Verificationr</title>
			</Helmet>
			<Form className="form" onSubmit={formik.handleSubmit}>
				<img className="rat-img" alt="rat" src={Rat} />
				<h3 className="resend-heading">Email Verification</h3>
				{!mailSend && (
					<>
						<p className="resend-subtitle">
							Your email is not verified yet. Please check your verification
							email sent to your mail or resend verification mail.
						</p>

						<Form.Group controlId="formBasicEmail">
							<Form.Control
								name="email"
								control="input"
								className="resend-input"
								type="email"
								placeholder="Enter email"
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								required
							/>
							{formik.touched.email && !!formik.errors.email && (
								<p className="error">{formik.errors.email}</p>
							)}
						</Form.Group>

						<Button
							className="tiger my-4 resend-verify-up-btn"
							block
							type="submit"
							variant="success"
						>
							Resend Verification Email
						</Button>
					</>
				)}

				{mailSend && (
					<p className="resend-subtitle">
						An email has been sent to your email address for validation. Please
						click on the link provided in the email to complete the registration
						process and continue playing.
					</p>
				)}

				<div className="d-flex justify-content-center">
					<Button
						className="tiger  home-button"
						onClick={() =>
							history.push(
								room ? `/user/sign-in?room=${room}` : "/user/sign-in"
							)
						}
						variant="secondary"
					>
						Back
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default ResendVerifyMail;
