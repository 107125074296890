import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { authHostAPI } from "../../api/authHost-api";
import { Helmet } from "react-helmet";
import Rat from "../../assets/images/triviarat-sign-up.png";

import "./style.scss";

const ChangingPassword = () => {
  const [values, setValues] = useState({});
  const [availablePass, setAvailablePass] = useState(false);
  const [shortPassword, setShortPassword] = useState(false);
  const [wrongConfirmPassword, setWrongConfirmPassword] = useState(false);

  const { resToken } = useParams();

  const goBack = () => (window.location.pathname = "/");

  useEffect(() => {
    authHostAPI
      .verifyToken(resToken)
      .then((res) => {})
      .catch((err) => {
        if (err.response.status === 404) {
          window.history.pushState({ param: "Value" }, "", "/reset-password");
          window.location.reload();
        }
      });
  }, [resToken]);

  const handleChange = (e) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const changePassword = (e) => {
    e.preventDefault();
    setAvailablePass(false);
    setShortPassword(false);
    setWrongConfirmPassword(false);
    if (values.newPassword) {
      if (values.newPassword.length >= 8) {
        if (values.newPassword === values.confirmPassword) {
          authHostAPI
            .newPass(resToken, values.newPassword, values.confirmPassword)
            .then((res) => {
              setValues({});
              localStorage.setItem(
                "user",
                JSON.stringify({
                  role: res.role,
                  auth: res.token,
                })
              );
            })
            .then(() => {
              window.location.pathname = "/host/";
            })
            .catch((err) => {});
        } else {
          setWrongConfirmPassword(true);
        }
      } else {
        setShortPassword(true);
      }
    } else {
      setAvailablePass(true);
    }
  };
  return (
    <div className="landing-view">
      <Helmet>
        <title>Trivia Rat</title>
      </Helmet>
      <div className="reset-password">
        <Container>
          <div className="d-flex justify-content-center">
            <Form onSubmit={changePassword} className="reset-form">
              <div className="text-center">
                <img className="rat-img" alt="Rat" src={Rat} />
                <h1>Reset Password</h1>
              </div>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  control="input"
                  onChange={handleChange}
                  required
                />
                {availablePass ? (
                  <p className="error">New password is required</p>
                ) : null}
                {shortPassword ? (
                  <p className="error">Password must be 8 or more characters</p>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  control="input"
                  onChange={handleChange}
                  required
                />
                {wrongConfirmPassword ? (
                  <p className="error">
                    Your password and confirmation password do not match
                  </p>
                ) : null}
              </Form.Group>
              <Button type="submit" variant="success">
                Change Password
              </Button>
              <Button variant="secondary" className="mb-3" onClick={goBack}>
                Cancel
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ChangingPassword;
