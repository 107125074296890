import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

const PlayerHeader = ({ roomCode }) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const logOut = () => {
		if (user && user.role === "player" || user && user.role === "user") {
			localStorage.removeItem("user");
			window.location.pathname = "/" + roomCode;
			return;
		}
		window.location.pathname = "/";
	};
	return (
		<div className="player-header">
			<div className="p-2 header-content d-flex justify-content-between">
				<div className="room-code">
					<span className="corn">ROOM : </span>
					<span className="corn" style={{ fontWeight: "bolder" }}>
						{roomCode}
					</span>
				</div>
				<Button onClick={logOut}>{user ? "LOGOUT" : "QUIT"}</Button>
			</div>
		</div>
	);
};

export default PlayerHeader;
