import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import QueryString from "qs";
import { Link, useHistory, useLocation } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import { authHostAPI } from "../../api/authHost-api";
import useForm from "../../util/authForm/useForm";
import validate from "../../util/authForm/validate";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import Loader from "../../pages/Admin/Game/Play/Loader";

import GoogleAuth from "../../components/GoogleAuth/GoogleAuth.js";

import Rat from "../../assets/images/triviarat-sign-up.png";
import "../formStyles.scss";
import "./style.scss";
import { set } from "react-ga";

const SignUp = () => {
	// const [isVerified, setVerified] = useState(true);
	const [existingEmail, setExistingEmail] = useState(false);
	const [source, setSource] = useState("");
	const [locationData, setLocationData] = useState({});
	const [loading, setLoading] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");

	const location = useLocation();
	const history = useHistory();

	ReactGA.initialize([{ trackingId: process.env.REACT_APP_TRACKING_ID }]);

	// const recaptchaRef = useRef();

	const { values, errors, handleChange, handleSubmit } = useForm(
		signUp,
		validate
	);

	// const toggleVerify = () => {
	//   if (recaptchaRef.current.getValue()) {
	//     setVerified(true);
	//   } else {
	//     setVerified(false);
	//   }
	// };

	useEffect(() => {
		console.log("Source", source);
		const getUserCountry = async () => {
			try {
				const response = await fetch("https://ipapi.co/json/");
				const data = await response.json();
				setLocationData(data);

				console.log("Data", data.country_name);
			} catch (error) {
				console.log("Error fetching user country:", error);
			}
		};

		if (!source) {
			let utm_source = QueryString.parse(location.search, {
				ignoreQueryPrefix: true,
			}).utm_source;

			if (utm_source) {
				localStorage.setItem("utm_source", utm_source);
			} else {
				utm_source = localStorage.getItem("utm_source");
			}

			let room_utm_source = localStorage.getItem("room_utm_source") ?? "";
			if (room_utm_source) {
				utm_source = utm_source + "-r" + room_utm_source;
			}
			setSource(utm_source);
		}
		getUserCountry();

		history.replace({
			search: "",
		});
	}, [location.search, source, history]);

	function signUp() {
		setErrorMessage("");

		if (values.agree === undefined) {
			alert("You must agree to the terms & conditions.");
			return;
		}
		setExistingEmail(false);
		authHostAPI
			.signUp(values.email.toLowerCase(), values.password, source, locationData)
			.then((res) => {
				localStorage.setItem(
					"user",
					JSON.stringify({
						role: "auth",
						auth: res.token,
					})
				);
				localStorage.removeItem("utm_source");
			})
			.then(() => {
				window.location.pathname = "/host/dashboard?welcome=true";

				window.gtag_report_conversion_free();

				window.scrollTo(0, 0);
			})
			.catch((err) => {
				if (err.response.status === 422) {
					setExistingEmail(true);
				} else {
					let msg = err.response.data.message ?? "Something went wrong!";
					setErrorMessage(msg);
					//alert(msg);
				}
			});
	}
	return (
		<div className="sign-up">
			<Helmet>
				<title>{window.APP_NAME} - Sign Up/Register</title>
			</Helmet>
			{window.APP_IS_WHITE_LABEL ? (
				<div className="d-flex flex-column m-auto text-center justify-content-center mb-4">
					<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLVddAc5uo-e3pZIoIjCpxr1LYee5zXR0Zs6NtrOFibJxuem2uGSQg3Rt82KZiHWLqzw&usqp=CAU" />
					<div class="mt-2 text-danger font-weight-bold my-4">HOST SIGNUP</div>
					<div className="subtitle-2 text-left mb-3">
						If you are a player, please go back to{" "}
						<a href="https://play.sunsettrivia.com">play.sunsettrivia.com</a>{" "}
						and click 'Join a Game'.
						<br />
						<br />
						If you are a host, please <a href="/sign-in">login</a> with the
						credentials provided to you. (Note that the username is the same as
						the roomcode).
					</div>
				</div>
			) : null}
			{!window.APP_IS_WHITE_LABEL ? (
				<Form className="form" onSubmit={handleSubmit}>
					{window.APP_IS_WHITE_LABEL ? (
						<div className="d-flex justify-content-center mb-4">
							<h5>Sign In</h5>
						</div>
					) : (
						<img className="rat-img" alt="Rat" src={Rat} />
					)}
					<Form.Group controlId="formBasicEmail">
						<Form.Control
							name="email"
							control="input"
							className="signup-input"
							type="email"
							value={values.email ? values.email.toLowerCase() : ""}
							placeholder="Enter email"
							onChange={handleChange}
							required
						/>
						{errors.email && <p className="help is-danger">{errors.email}</p>}
						{existingEmail ? (
							<p className="help is-danger">Email already exist!</p>
						) : null}
					</Form.Group>

					<Form.Group controlId="formBasicPassword">
						<Form.Control
							className="signup-input"
							type="password"
							name="password"
							onChange={handleChange}
							value={values.password || ""}
							placeholder="Password"
							required
						/>
						{errors.password && (
							<p className="help is-danger">{errors.password}</p>
						)}
					</Form.Group>
					<Form.Group controlId="formAgreeToTerms" className="termsCheckbox">
						<input
							type="checkbox"
							name="agree"
							value={values.agree}
							onChange={handleChange}
						/>
						<span className="termsCheckboxLabel">
							Agree to{" "}
							<a href="/terms-and-conditions" target="_blank">
								Terms & Conditions
							</a>
						</span>
					</Form.Group>

					{/* <ReCAPTCHA
          className="reCaptcha-size"
          ref={recaptchaRef}
          sitekey="6Lej8LUZAAAAAMrtyPryAfV1favYPq73lePaYo6y"
          onChange={toggleVerify}
        /> */}
					<Button
						className="tiger my-4 sign-up-btn"
						block
						type="submit"
						variant="success"
						disabled={loading}
					>
						{loading ? "Signing In.." : "SIGN UP!"}
					</Button>

					{loading && (
						<div className="d-flex justify-content-center">
							<Loader />
						</div>
					)}

					<hr />
					<GoogleAuth
						onGoogleAuthFail={(error, isSignUp) => {
							console.log("error", error);
							if (error.error === "popup_closed_by_user") {
								return;
							}
							setErrorMessage(
								"Something went wrong! Please try again or use a different method."
							);
						}}
						onSetLoading={(loading) => {
							setLoading(loading);
						}}
						type={"signup_with"}
					/>

					{errorMessage ? (
						<div className="alert alert-danger small" role="alert">
							{errorMessage}
						</div>
					) : null}

					<p className="text-center message">
						Already registered? <Link to="/sign-in">Sign In</Link>
					</p>
					<div className="d-flex justify-content-center">
						<a href="/">
							<Button className="tiger my-4 home-button" variant="secondary">
								Home
							</Button>
						</a>
					</div>
				</Form>
			) : null}
		</div>
	);
};

export default SignUp;
