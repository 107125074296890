import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({
	removePlayer,
	removePlayers,
	confirm,
	submitBtnText,
	cancelBtnText,
	loading = false,
	...props
}) => {
	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			backdrop="static"
			keyboard={false}
			size="md"
			className="admin-view"
			centered
		>
			<div className="tips-modal">
				<Modal.Body>
					<button
						onClick={props.handleClose}
						type="button"
						className="bootbox-close-button close"
						aria-hidden="true"
					>
						×
					</button>
					<div>{props.children}</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="p-2">
						<Button variant="secondary" onClick={props.handleClose}>
							{cancelBtnText || "Cancel"}
						</Button>
						<Button type="submit" variant="primary" onClick={confirm} disabled={loading}>
							{submitBtnText || "OK"}
						</Button>
					</div>
				</Modal.Footer>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
