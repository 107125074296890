import React, { useEffect, useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import QueryString from "qs";
import { Link, useHistory, useLocation } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
import { authHostAPI } from "../../api/authHost-api";
import useForm from "../../util/authForm/useForm";
import validate from "../../util/authForm/validate";
import { Helmet } from "react-helmet";
//import ReactGA from "react-ga";

import "./style.scss";

const TermsAndConditions = () => {
	return (
		<>
			<div className="terms">
				<Helmet>
					<title>TriviaRat.com - Terms & Conditions</title>
				</Helmet>

				<div>
					<h1>Terms and Conditions for TriviaRat</h1>

					<p>
						<strong>Last Updated:</strong> Nov 20, 2022
					</p>

					<p>
						Welcome to TriviaRat! These Terms and Conditions outline the rules
						and regulations for the use of TriviaRat's Website, located at
						triviarat.com.
					</p>

					<p>
						By accessing this website, we assume you accept these Terms and
						Conditions. Do not continue to use TriviaRat if you do not agree to
						take all of the terms and conditions stated on this page.
					</p>

					<h4>1. User Account Registration</h4>
					<ul>
						<li>
							Users must register for an account by providing a valid email
							address and creating a password.
						</li>
					</ul>

					<h4>2. Privacy and Data Protection</h4>
					<p>
						TriviaRat is committed to ensuring the protection and security of
						your personal information. Your passwords are encrypted and stored
						securely. Personal data provided during registration will be used in
						accordance with our Privacy Policy. We may use your email address to
						send you occasional updates about new features, updates, and
						transactional emails like password resets.
					</p>

					<h4>3. User Responsibilities</h4>
					<p>
						You are responsible for maintaining the confidentiality of your
						account and password. You agree to accept responsibility for any and
						all activities or actions that occur under your account and/or
						password. You must not use TriviaRat for any illegal or unauthorized
						purpose.
					</p>

					<h4>4. Intellectual Property Rights</h4>
					<p>
						The content, arrangement, and layout of this site, including, but
						not limited to, the trademarks, photos, logos, text, and other
						intellectual property herein, are owned by TriviaRat, except as
						otherwise expressly stated.
					</p>

					<h4>5. Payment and Transactions</h4>
					<ul>
						<li>
							Transactions on TriviaRat are processed via PayPal, a third-party
							payment service provider.
						</li>
						<li>
							By choosing to transact through PayPal, you agree to PayPal's
							terms and conditions.
						</li>
						<li>
							TriviaRat is not responsible for any issues related to
							transactions and payments processed through PayPal.
						</li>
						<li>
							Refunds and disputes for transactions processed through PayPal are
							subject to PayPal's policies.
						</li>
					</ul>

					<h4>6. Limitation of Liability</h4>
					<p>
						TriviaRat is not liable for any damages or injuries resulting from
						your use of any of its services. We do not guarantee the accuracy,
						reliability, or continuity of any of the services provided
					</p>

					<h4>7. Termination</h4>
					<p>
						We may terminate or suspend access to our service immediately,
						without prior notice or liability, for any reason whatsoever,
						including, without limitation, if you breach the Terms and
						Conditions.
					</p>

					<h4>8. Governing Law</h4>
					<p>
						These Terms shall be governed and construed in accordance with the
						laws of [Your Country/State], without regard to its conflict of law
						provisions.
					</p>

					<h4>9. Changes to Terms and Conditions</h4>
					<p>
						We reserve the right, at our sole discretion, to modify or replace
						these Terms at any time. By continuing to access or use our service
						after any revisions become effective, you agree to be bound by the
						revised terms.
					</p>

					<p>
						<strong>Contact Information</strong>
					</p>
					<p>
						If you have any questions about these Terms, please contact us at:
						contact@triviarat.com
					</p>
				</div>
				<div>
					<Link to="/sign-up">
						<Button>Back</Button>
					</Link>
				</div>
			</div>
		</>
	);
};

export default TermsAndConditions;
