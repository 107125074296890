import React, { useEffect, useState } from "react";

import packageJson from "../../../package.json";
import { Button, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { authHostAPI } from "../../api/authHost-api";

import { useFormik } from "formik";
import * as Yup from "yup";
import "../formStyles.scss";
import "./style.scss";

import Loader from "../../pages/Admin/Game/Play/Loader";
import GoogleAuth from "../../components/GoogleAuth/GoogleAuth.js";

import Rat from "../../assets/images/triviarat-sign-up.png";

const version = packageJson.version;

const initialValues = {
	login: "",
	password: "",
};

const validationSchema = Yup.object().shape({
	login: Yup.string()
		.trim()
		.min(4, "Minimum 4 symbols")
		.required("Login is required"),
	password: Yup.string()
		.trim()
		.min(8, "Minimum 8 symbols")
		// .max(20, "Maximum 20 symbols")
		.required("Password is required"),
});

const SignIn = () => {
	const [locationData, setLocationData] = useState({});
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		const getUserCountry = async () => {
			try {
				const response = await fetch("https://ipapi.co/json/");
				const data = await response.json();
				setLocationData(data);

				console.log("Server Connect", data.country_name);
			} catch (error) {
				console.log("Error fetching user country:", error);
			}
		};

		getUserCountry();

		history.replace({
			search: "",
		});
	}, [history]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, { setFieldError, setSubmitting }) => {
			setSubmitting(true);
			authHostAPI
				.login(values.login.toLowerCase(), values.password, locationData)
				.then((res) => {
					localStorage.setItem(
						"user",
						JSON.stringify({
							role: res.role,
							auth: res.token,
						})
					);
				})
				.then(() => {
					window.location.pathname = "/host/";
				})
				.catch((err) => {
					if (err.response && err.response.status === 403) {
						setFieldError("login", err.response?.data?.message);
					} else if (err.response?.status === 409) {
						setFieldError(
							err.response?.data?.param,
							err.response?.data?.message
						);
					} else {
						alert(err);
					}
				});
		},
	});

	return (
		<div className="sign-in">
			<Helmet>
				<title>{window.APP_NAME} - Signup/Register</title>
			</Helmet>
			{window.APP_IS_WHITE_LABEL ? (
				<div className="d-flex flex-column text-center m-auto justify-content-center mb-4">
					<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLVddAc5uo-e3pZIoIjCpxr1LYee5zXR0Zs6NtrOFibJxuem2uGSQg3Rt82KZiHWLqzw&usqp=CAU" />
					<div class="mt-2 text-danger font-weight-bold">HOST LOGIN</div>
					<div className="subtitle text-left mb-3">
						If you are a player, please go back to{" "}
						<a href="https://play.sunsettrivia.com">play.sunsettrivia.com</a>{" "}
						and click 'Join a Game'.
					</div>
				</div>
			) : null}

			<Form className="form" onSubmit={formik.handleSubmit}>
				{window.APP_IS_WHITE_LABEL ? (
					<div className="d-flex justify-content-center mb-4">
						<h5>Sign In</h5>
					</div>
				) : (
					<img className="rat-img" alt="Rat" src={Rat} />
				)}

				<Form.Group controlId="formBasicLogin">
					<Form.Control
						type="text"
						placeholder="Enter login"
						name="login"
						control="input"
						value={formik.values.login}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						required
						className="login-input"
					/>
					{formik.touched.login && !!formik.errors.login && (
						<p className="error">{formik.errors.login}</p>
					)}
				</Form.Group>
				<Form.Group controlId="formBasicPassword">
					<Form.Control
						type="password"
						placeholder="Password"
						name="password"
						onChange={formik.handleChange}
						value={formik.values.password}
						onBlur={formik.handleBlur}
						required
						className="login-input"
					/>
					{formik.touched.password && !!formik.errors.password && (
						<p className="error">{formik.errors.password}</p>
					)}
					<a href="reset-password" className="forgot-btn float-right">
						Forgot Password?
					</a>
				</Form.Group>
				<Button
					variant="success"
					className="tiger sign-in-btn"
					block
					type="submit"
					disabled={formik.isSubmitting}
				>
					LOGIN!
				</Button>

				{loading && (
					<div className="d-flex justify-content-center">
						<Loader />
					</div>
				)}

				<GoogleAuth
					onGoogleAuthFail={(error, isSignUp) => {
						console.log("error", error);
						if (error.error === "popup_closed_by_user") {
							return;
						}
						setErrorMessage(
							"Something went wrong! Please try again or use a different method."
						);
					}}
					onSetLoading={(loading) => {
						setLoading(loading);
					}}
					type={"signin_with"}
				/>

				{errorMessage ? (
					<div className="alert alert-danger small" role="alert">
						{errorMessage}
					</div>
				) : null}

				<p className="text-center message">
					Not registered? <Link to="/sign-up"> Create an account</Link>
				</p>
				<div className="d-flex justify-content-center">
					<a href="/">
						<Button className="tiger my-4 home-button" variant="secondary">
							Home
						</Button>
					</a>
				</div>
				<div className="d-flex justify-content-center subtitle">
					v: {version}
				</div>
			</Form>
		</div>
	);
};

export default SignIn;
