import React, { useEffect, useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import QueryString from "qs";
import { Link, useHistory, useLocation } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
import { authHostAPI } from "../../api/authHost-api";
import useForm from "../../util/authForm/useForm";
import validate from "../../util/authForm/validate";
import { Helmet } from "react-helmet";
//import ReactGA from "react-ga";

import "./style.scss";

const SignUpVenue = () => {
	const [isVerified, setVerified] = useState(true);
	const [country, setCountry] = useState("");
	const [locationData, setLocationData] = useState({});

	const location = useLocation();
	const history = useHistory();
	let [signupComplete, setSignupComplete] = useState(false);
	let [showMore, setShowMore] = useState(false);

	const { values, errors, handleChange, handleSubmit } = useForm(
		signUp,
		validate
	);

	useEffect(() => {
		// Function to get the user's country
		const getUserCountry = async () => {
			try {
				const response = await fetch("https://ipapi.co/json/");
				const data = await response.json();
				//setCountry(data.country_name);
				setLocationData(data);
				console.log("Data", data.country_name);
			} catch (error) {
				console.log("Error fetching user country:", error);
			}
		};

		getUserCountry();
	}, []);

	const goToLive = () => {
		window.location.href = "https://live.triviarat.com/?utm_source=venuesignup";
	};

	function signUp() {
		//setExistingEmail(false);
		authHostAPI
			.signUpVenue(
				values.venueName,
				values.fullName,
				values.email.toLowerCase(),
				locationData
			)
			.then(() => {
				//window.location.pathname = "/venue-admin/dashboard";
				setSignupComplete(true);
				window.scrollTo(0, 0);
			})
			.catch((err) => {
				if (err.response.status === 422) {
					// setExistingEmail(true);
				} else {
					alert(err);
				}
			});
	}
	return (
		<>
			<div className="sign-up">
				<Helmet>
					<title>TriviaRat.com - Venue Register</title>
				</Helmet>
				<Form className="form" onSubmit={handleSubmit}>
					<strong className="d-flex justify-content-center">
						Host Connect
					</strong>
					<p className="subtitle-2">
						Connect with professional trivia hosts in your area.{" "}
						{!!showMore || (
							<a href="#" onClick={() => setShowMore(true)}>
								Learn More..
							</a>
						)}
					</p>

					{showMore ? (
						<div class="subtitle-2">
							<p>
								Tired of slow nights at your bar? Time to shake things up and
								bring the crowds back with Trivia! A solid night of trivia is
								the ultimate solution to revitalize your venue. Picture this:
								packed tables, buzzing energy, and a lively atmosphere that'll
								make your bar the hottest spot in town on even the dullest
								evenings.
							</p>
							<p>
								No more worrying about complicated logistics or equipment.
								Triviarat streamlines the process, connecting you with qualified
								hosts, allowing you to focus on what matters most: packing your
								bar with enthusiastic patrons. As your customers bond over
								brain-teasing challenges, your venue comes alive with laughter,
								friendly competition, and the sweet sound of ringing cash
								registers. Get ready for a simple, fun-filled trivia night that
								will leave everyone wanting more!{" "}
								<a href="#" onClick={() => setShowMore(false)}>
									Show Less
								</a>
							</p>
						</div>
					) : null}

					{signupComplete ? (
						<>
							<Alert variant={"success"}>
								Thank you for signing up. A representative will reach out soon
								with next steps!
							</Alert>
							<div className="mb-2">
								Note: If you would like to host the trivia game yourself,
								sign-up for a host account{" "}
								<a href="https://triviarat.com/sign-up?utm_source=venuesignup">
									HERE
								</a>
								<br />
							</div>
							<a href="https://triviarat.com/" className="mt-4 btn btn-primary">
								Back
							</a>
						</>
					) : (
						<>
							<label>Venue Name</label>
							<Form.Group controlId="formVenueName">
								<Form.Control
									className="signup-input"
									type="text"
									name="venueName"
									onChange={handleChange}
									value={values.venueName || ""}
									placeholder="Teds Bar & Grill"
									required
								/>
								{errors.venueName && (
									<p className="help is-danger">{errors.venueName}</p>
								)}
							</Form.Group>
							<label>Your name</label>
							<Form.Group controlId="formName">
								<Form.Control
									className="signup-input"
									type="text"
									name="fullName"
									onChange={handleChange}
									value={values.fullName || ""}
									placeholder="John Smith"
									required
								/>
								{errors.fullName && (
									<p className="help is-danger">{errors.fullName}</p>
								)}
							</Form.Group>

							<label>Email</label>
							<Form.Group controlId="formBasicEmail">
								<Form.Control
									name="email"
									control="input"
									className="signup-input"
									type="email"
									value={values.email ? values.email.toLowerCase() : ""}
									placeholder="wilson@acme.com"
									onChange={handleChange}
									required
								/>
								{errors.email && (
									<p className="help is-danger">{errors.email}</p>
								)}
							</Form.Group>

							<Button
								className="tiger my-4 sign-up-btn"
								block
								type="submit"
								variant="success"
								onClick={() => signUp()}
							>
								SIGN UP!
							</Button>

							<a
								href="https://triviarat.com/"
								className="mt-4 btn btn-link btn-sm text-center d-flex justify-content-center"
							>
								TriviaRat Home
							</a>
						</>
					)}
				</Form>

				<div>
					<p class="subtitle text-center">Looking for something else? Try..</p>

					<Button
						className="tiger my-4 sign-up-btn"
						block
						type="submit"
						variant="primary"
						onClick={goToLive}
					>
						{" "}
						<strong>TriviaRat LIVE!</strong>
					</Button>
				</div>
			</div>
		</>
	);
};

export default SignUpVenue;
