import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import { Link } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import { userHostApi } from "../../../api/user-api";
import useForm from "../../../util/authForm/useForm";
import validate from "../../../util/authForm/validate";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import Rat from "../../../assets/images/triviarat-sign-up.png";
import "../../formStyles.scss";
import "../user.scss";
import useQuery from "../../../util/getQuery";
import UserFooter from "../Components/UserFooter";

const SignUp = () => {
	const [existingEmail, setExistingEmail] = useState(false);
	const [error, setError] = useState("");

	ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
	ReactGA.pageview("/sign-up");

	const { values, errors, handleChange, handleSubmit } = useForm(
		signUp,
		validate
	);

	const query = useQuery();
	const room = query.get("room");

	const [mailSend, setMailSent] = useState(false);
	function signUp() {
		// if (values.agree == undefined) {
		// 	alert("You must agree to the terms & conditions.");
		// 	return;
		// }
		const baseUrl = window.location.origin;

		setExistingEmail(false);
		userHostApi
			.signUp(values.email.toLowerCase(), values.password, room, baseUrl)
			.then(() => {
				setMailSent(true);
				localStorage.removeItem("utm_source");
			})
			.catch((err) => {
				setError(err.response?.data?.message);
				if (err.response.status === 422) {
					setExistingEmail(true);
				} else {
					//alert(err);
				}
			});
	}

	return (
		<div className="sign-up">
			<Helmet>
				<title>TriviaRat.com - Signup/Register</title>
			</Helmet>
			{window.APP_IS_WHITE_LABEL ? (
				<div className="d-flex justify-content-center mb-4">
					<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLVddAc5uo-e3pZIoIjCpxr1LYee5zXR0Zs6NtrOFibJxuem2uGSQg3Rt82KZiHWLqzw&usqp=CAU" />
				</div>
			) : null}

			<div className="d-flex justify-content-center flex-column align-center text-center my-4">
				<p className="overline">ROOM</p>
				<h4 className="thick">{room}</h4>
			</div>

			<Form className="form" onSubmit={handleSubmit}>
				{window.APP_IS_WHITE_LABEL ? (
					<div className="d-flex justify-content-center mb-2">
						<h5>Team Registration</h5>
					</div>
				) : (
					<div className="d-flex justify-content-center mb-2">
						<h5>User Registration</h5>
					</div>
				)}
				<p className="subtitle">
					Register for an account to save your game progress and maintain data
					across multiple games and rooms.{" "}
				</p>

				{window.APP_IS_WHITE_LABEL ? (
					<div className="subtitle-2 mb-4">
						Please use an email address that you check frequently as this will
						be how sunset trivia communicates about tournament information with
						you.
					</div>
				) : (
					""
				)}

				{!mailSend && (
					<>
						<Form.Group controlId="formBasicEmail">
							<Form.Control
								name="email"
								control="input"
								className="signup-input"
								type="email"
								value={values.email ? values.email.toLowerCase() : ""}
								placeholder="Enter email"
								onChange={handleChange}
								required
							/>
							{errors.email && <p className="help is-danger">{errors.email}</p>}
							{existingEmail ? (
								<p className="help is-danger">Email already exist!</p>
							) : null}
						</Form.Group>

						<Form.Group controlId="formBasicPassword">
							<Form.Control
								className="signup-input"
								type="password"
								name="password"
								onChange={handleChange}
								value={values.password || ""}
								placeholder="Password"
								required
							/>
							{errors.password && (
								<p className="help is-danger">{errors.password}</p>
							)}
						</Form.Group>
						{/* <Form.Group controlId="formAgreeToTerms" className="termsCheckbox">
							{window.APP_IS_WHITE_LABEL ? null : (
								<>
									<input
										type="checkbox"
										name="agree"
										value={values.agree}
										onChange={handleChange}
									/>

									<span className="termsCheckboxLabel">
										Agree to{" "}
										<a
											href="https://triviarat.com/terms-and-conditions.html"
											target="_BLANK"
											required
										>
											Terms & Conditions
										</a>
									</span>
								</>
							)}
						</Form.Group> */}

						<Button
							className="tiger my-4 sign-up-btn"
							block
							type="submit"
							variant="success"
						>
							SIGN UP!
						</Button>
						<p className="text-center message">
							Already registered?{" "}
							<Link to={room ? `/user/sign-in?room=${room}` : "/user/sign-in"}>
								Sign In
							</Link>
						</p>
						<div className="d-flex justify-content-center">
							<a href="/">
								<Button className="tiger my-4 home-button" variant="secondary">
									Home
								</Button>
							</a>
						</div>

						<div>
							{error && (
								<div className="alert alert-danger" role="alert">
									{error}
								</div>
							)}
						</div>
					</>
				)}

				{mailSend && (
					<>
						<h3 className="resend-heading">Email Verification</h3>
						{/* <p className="resend-subtitle">
							An email has been sent to your email address. Please click on the
							link provided in the email to complete the registration process.
						</p> */}
						<p className="resend-subtitle">
							Thank you for registering! Please activate your account to
							continue
							<br />
							<Link to={room ? `/user/sign-in?room=${room}` : "/user/sign-in"}>
								Sign In
							</Link>
						</p>
					</>
				)}
			</Form>

			<UserFooter />
		</div>
	);
};

export default SignUp;
