import axios from "axios";
import { checkAuth } from "../util/localStorage";
import { baseURL } from "../util/constants";

const api = axios.create({ baseURL });

export const userHostApi = {
	signUp(email, password, room, baseUrl) {
		return api
			.post("user/sign-up", {
				email,
				password,
				room,
				baseUrl,
			})
			.then((res) => {
				return res.data;
			});
	},
	login(email, password) {
		return api
			.post("user/login", {
				email,
				password,
			})
			.then((res) => {
				return res.data;
			});
	},
	forgotPassword(email, baseUrl = "") {
		return api
			.post("user/reset-password", {
				email: email,
				baseUrl: baseUrl,
			})
			.then((res) => {
				return res.data;
			});
	},
	resetPassword(
		resToken,
		newPass,
		confirmPass,
		baseUrl = "https://triviarat.com"
	) {
		return api
			.post("user/set-password", {
				resetToken: resToken,
				password: newPass,
			})
			.then((res) => {
				return res.data;
			});
	},
	resendVerification(email, room, baseUrl = "https://triviarat.com") {
		return api
			.post("user/resend-email", {
				email,
				room,
				baseUrl,
			})
			.then((res) => {
				return res.data;
			});
	},
	verifyAccount(verificationToken) {
		return api.get(`user/verify/${verificationToken}`).then((res) => res.data);
	},
};
