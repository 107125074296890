import React, { useEffect, useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import QueryString from "qs";
import { Link, useHistory, useLocation } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
import { authHostAPI } from "../../api/authHost-api";
import useForm from "../../util/authForm/useForm";
import validate from "../../util/authForm/validate";
import { Helmet } from "react-helmet";
//import ReactGA from "react-ga";

import "./style.scss";

const TermsAndConditions = () => {
	return (
		<>
			<div className="terms">
				<Helmet>
					<title>TriviaRat.com - Terms & Conditions</title>
				</Helmet>

				<div>
					<section>
						<div class="how-it-works">
							<hr />
							<div class="box">
								<div id="maincontent">
									<div class="container">
										<h1>Privacy Policy</h1>
										<p>
											<strong>Last Updated:</strong> May 4, 2023
										</p>
										<p>
											Triviarat ("we," "us," or "our") is committed to
											protecting the privacy of our users. This Privacy Policy
											outlines how we collect, use, and protect your information
											when you use our trivia hosting platform, available at
											triviarat.com (the "Platform").
										</p>
										<p>
											By using the Platform, you agree to the collection and use
											of your information in accordance with this Privacy
											Policy.
										</p>
										<h4>1. Information We Collect</h4>
										<p>
											When you use the Platform, we collect the following types
											of information:
										</p>
										<ul>
											<li>
												<strong>Personal Information:</strong>
												We collect your name and email address when you sign up
												for an account or participate in our trivia games.
											</li>
											<li>
												<strong>Game Information:</strong>
												We collect information about the trivia games you
												participate in, such as your responses, scores, and game
												settings.
											</li>
											<li>
												<strong>Cookies:</strong> We use cookies to remember
												your login information and maintain your session when
												you use the Platform on your mobile device.
											</li>
										</ul>
										<h4>2. How We Use Your Information</h4>
										<p>We use your information for the following purposes:</p>
										<ul>
											<li>
												<strong>To provide and maintain the Platform</strong>
												and our services, including processing your responses
												and scores.
											</li>
											<li>
												<strong>To improve and enhance the Platform</strong>
												by analyzing game information, particularly game
												settings, to optimize gameplay and user experience.
											</li>
											<li>
												<strong>To communicate with you</strong>
												about your account or any updates related to the
												Platform.
											</li>
										</ul>
										<h4>3. Sharing Your Information</h4>
										<p>
											We do not share your personal information with third
											parties.
										</p>
										<h4>4. Data Security</h4>
										<p>
											We take the security of your information seriously. We
											implement encryption and authentication measures to
											protect your data from unauthorized access, alteration,
											disclosure, or destruction.
										</p>
										<h4>
											5. Accessing, Modifying, and Deleting Your Information
										</h4>
										<p>
											You can access, modify, or delete your personal
											information by logging into your account on the Platform.
											If you need further assistance, please contact us at
											contact@triviarat.com.
										</p>
										<h4>6. Changes to This Privacy Policy</h4>
										<p>
											We may update our Privacy Policy from time to time. We
											will notify you of any changes by posting the new Privacy
											Policy on this page and updating the "Last Updated" date
											at the top of this Privacy Policy. You are advised to
											review this Privacy Policy periodically for any changes.
										</p>
										<h4>Contact Us</h4>
										<div></div>
										If you have any questions, concerns, or requests related to
										this Privacy Policy or our privacy practices, please contact
										us at: contact@triviarat.com
										<p>
											By using the Platform, you acknowledge that you have read
											and understand this Privacy Policy and agree to its terms.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div>
					<Link to="/sign-up">
						<Button>Back</Button>
					</Link>
				</div>
			</div>
		</>
	);
};

export default TermsAndConditions;
