import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router/Router";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
	const url = window.location.href;
	window.APP_BASE_URL = url;
	window.APP_IS_WHITE_LABEL = url.includes("play.");
	window.APP_NAME = !window.APP_IS_WHITE_LABEL ? "TriviaRat" : "Sunset Trivia";
	window.APP_SITE_LABEL = !window.APP_IS_WHITE_LABEL
		? "triviarat.com"
		: "play.sunsettrivia.com";

	return (
		<GoogleOAuthProvider clientId="720549866724-1csgnhshgbh0n79tkajh76n2kdiuadda.apps.googleusercontent.com">
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</GoogleOAuthProvider>
	);
}

export default App;
