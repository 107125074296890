import React, { useState } from "react";
import "./style.scss";
import { Container, Button, Form } from "react-bootstrap";
import { authHostAPI } from "../../api/authHost-api";
import { Helmet } from "react-helmet";

import Rat from "../../assets/images/triviarat-sign-up.png";
import { set } from "react-ga";

const ResetPassword = () => {
	const [values, setValues] = useState({});
	const [wrongEmail, setWrongEmail] = useState(false);
	const [resetedPass, setResetedPass] = useState(false);
	const [serverError, setServerError] = useState("");

	const goBack = () => (window.location.pathname = "/");

	const resetPassword = (e) => {
		e.preventDefault();
		setWrongEmail(false);
		setServerError("");

		authHostAPI
			.resetPass(values.email)
			.then((val) => {
				setResetedPass(true);
			})
			.catch((err) => {
				if (err.response?.status === 404) {
					setWrongEmail(true);
					return;
				}
				console.log("setting error", err);
				setServerError(
					err.response?.data?.message ||
						"Server Error Please try again later or contact support at support@triviarat.com"
				);
			});
	};

	const handleChange = (e) => {
		e.persist();
		setValues((values) => ({
			...values,
			[e.target.name]: e.target.value,
		}));
	};

	return (
		<div>
			<Helmet>
				<title>{window.APP_NAME}</title>
			</Helmet>
			<div className="reset-password">
				<Container>
					<Form onSubmit={(e) => resetPassword(e)}>
						<div className="text-center">
							{window.APP_IS_WHITE_LABEL ? (
								<div className="d-flex justify-content-center mb-4">
									<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLVddAc5uo-e3pZIoIjCpxr1LYee5zXR0Zs6NtrOFibJxuem2uGSQg3Rt82KZiHWLqzw&usqp=CAU" />
								</div>
							) : (
								<img className="rat-img" alt="Rat" src={Rat} />
							)}

							<h1>Password Reset</h1>
						</div>
						<Form.Group>
							{resetedPass ? (
								<div className="alert alert-warning">
									An email has been sent to: <b>{values.email}</b>. Please check
									your inbox and follow the instructions provided. Thanks!
								</div>
							) : (
								<Form.Control
									onChange={handleChange}
									type="email"
									name="email"
									size="lg"
									control="input"
									placeholder="Enter email"
									autoFocus={true}
									pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
									required
								/>
							)}
							{wrongEmail ? (
								<p className="error">There is no user with such email.</p>
							) : null}
							{serverError ? <p className="error">{serverError}</p> : null}
						</Form.Group>
						{resetedPass ? null : (
							<Button type="submit" variant="success" size="lg">
								Reset
							</Button>
						)}
						<Button variant="secondary" className="mb-3" onClick={goBack}>
							Back
						</Button>
					</Form>
				</Container>
			</div>
		</div>
	);
};

export default ResetPassword;
