import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { playerAPI } from "../../../../api/player-api";

import markingGif from "../../../../assets/images/checkbox.gif";
import { GameStoreContext } from "../../../../store/GameStore";

const Marking = ({
	showAnswers,
	answersFromSocket,
	updatedAnswer,
	setUpdatedAnswer,
	showCorrectAnswers,
	displayCorrectAnswer,
	roundMode,
	afterReconnect,
	setAfterReconnect,
}) => {
	const [answers, setAnswers] = useState([]);
	const [currentRound, setCurrentRound] = useState([]);

	useEffect(() => {
		if (updatedAnswer) {
			const answersToSet = answers;
			for (const prevAnswer of answers) {
				if (prevAnswer._id === updatedAnswer._id) {
					prevAnswer.point = updatedAnswer.point;
					prevAnswer.halfPoint = updatedAnswer.halfPoint;
					prevAnswer.marked = updatedAnswer.marked;
					prevAnswer.additionalPoints = updatedAnswer.additionalPoints;

					break;
				}
			}
			// setting to undefined to not to get infinite loop
			setUpdatedAnswer(undefined);
			setAnswers(answersToSet);
		}
	}, [updatedAnswer, answers, setUpdatedAnswer]);

	const getAnswers = useCallback(() => {
		if (showAnswers) {
			playerAPI
				.getAnswers()
				.then((res) => {
					const answersToSet = [];
					res.answers.forEach((answer) => {
						answersToSet.push(answer);
					});
					setAnswers(answersToSet);
					setCurrentRound(res.currentRound);
				})
				.catch((err) => {
					alert(err);
				});
		}
	}, [showAnswers]);

	useEffect(() => {
		if (showAnswers) {
			// if we have array of player answers from sockets we use it, else we send request to get it
			// if (!afterReconnect && answersFromSocket) {
			//   setAnswers(answersFromSocket);
			// } else {

			getAnswers();
			// }
		} else {
			setAnswers([]);
		}
		setAfterReconnect(false);
	}, [
		showAnswers,
		getAnswers,
		answersFromSocket,
		afterReconnect,
		setAfterReconnect,
	]);

	const getClassName = (answer) => {
		if (!answer.point && answer.marked) {
			return "player-answer wrong";
		} else if (answer.point) {
			return "player-answer point";
		} else if (answer.halfPoint) {
			return "player-answer half-point";
		} else {
			return "player-answer";
		}
	};

	return (
		<div className="marking-wrapper">
			<div className="d-flex flex-column align-items-center marking">
				{currentRound?.name ? (
					<h2 className="varitykiller">Round # {currentRound?.name}</h2>
				) : null}
				<h2 className="varitykiller">Marking...</h2>
				<img alt="Marking Gif" src={markingGif} />
				<br />
				{answers.map((answer) => {
					return (
						<>
							<div key={answer._id} className={getClassName(answer)}>
								<span>{answer.orderNum}) </span>
								<span>{answer.text}</span>
								{answer.marked && !answer.point && (
									<FontAwesomeIcon icon="times" className="mx-2" />
								)}
								{answer.point ? (
									<span>
										<FontAwesomeIcon icon="check" className="mx-2" />
									</span>
								) : null}
								{answer.halfPoint ? <span> 1/2</span> : null}
								{answer.additionalPoints !== 0 ? (
									<span>
										{` ${answer.additionalPoints > 0 ? "+" : ""}${
											answer.additionalPoints
										}`}
									</span>
								) : null}
								{(roundMode === "automatic" || roundMode === "pre-entered") &&
									displayCorrectAnswer && (
										<p className="correct-answer">
											Answer: {answer.answerText}
										</p>
									)}
							</div>
						</>
					);
				})}
			</div>
		</div>
	);
};

export default Marking;
