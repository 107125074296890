import axios from "axios";
import { baseURL } from "../util/constants";

const api = axios.create({ baseURL });

export const contactApi = {
	sendFeedback(email, message) {
		return api
			.post("contact/", {
				email,
				message,
			})
			.then((res) => {
				return res.data;
			});
	},
	//used for contacting when user is already logged in
	authContact(type = "support", email, message, meta = {}) {
		return api
			.post("contact/auth", {
				type,
				email,
				message,
				meta,
			})
			.then((res) => {
				return res.data;
			});
	},
};
