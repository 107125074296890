import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ user, ...rest }) => {
  if (user.role === "auth" || user.role === "pro" || user.role === "guest") {
    return <Route {...rest} />;
  } else if (user.role === "player") {
    return (
      <Redirect to={{ pathname: "/player", state: { from: rest.location } }} />
    );
  } else {
    window.location.pathname = "/";
  }
};

export default PrivateRoute;
