import React from "react";
import { Modal, Button } from "react-bootstrap";

const TipModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      className="admin-view"
    >
      <div className="tips-modal">
        <Modal.Body>
          <button
            onClick={props.handleClose}
            type="button"
            className="bootbox-close-button close"
            aria-hidden="true"
          >
            ×
          </button>
          <div>{props.children}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-2">
            <Button type="submit" variant="primary" onClick={props.handleClose}>
              OK
            </Button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default TipModal;
