import React from "react";
import { Redirect } from "react-router-dom";

const PlayerRoute = ({ user, component: Component, ...rest }) => {
  const roomUrl = window.location.pathname.replace("/", "");

  if (user.role === "player" && roomUrl === "player") {
    return <Component {...rest} />;
  } else if (user.role === "player") {
    return (
      <Redirect to={{ pathname: "/player", state: { from: rest.location } }} />
    );
  } else if (
    user.role === "auth" ||
    user.role === "pro" ||
    user.role === "guest"
  ) {
    return (
      <Redirect to={{ pathname: "/host", state: { from: rest.location } }} />
    );
  } else {
    window.location.pathname = "/";
  }
};

export default PlayerRoute;
