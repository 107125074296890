import React from "react";
import { Redirect, Route } from "react-router-dom";

const PublicRoute = ({ user, component: Component, ...rest }) => {
	//console.log("window.location.href", window.location.href);
	return (
		<Route
			{...rest}
			render={(routeProps) => {
				return user ? (
					user.role === "auth" ||
					user.role === "pro" ||
					user.role === "guest" ? (
						<Redirect
							to={{
								pathname: "/host",
								state: { from: routeProps.location },
							}}
						/>
					) : user.role === "player" ? (
						<Redirect
							to={{
								pathname: "/player",
								state: { from: routeProps.location },
							}}
						/>
					) : (
						<Component {...routeProps} />
					)
				) : (
					(window.location.pathname = "/")
				);
			}}
		/>
	);
};

export default PublicRoute;
