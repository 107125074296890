import React, { useState } from "react";

const UserFooter = () => {
	return (
		<div className="d-flex justify-content-center user-footer p-4">
			{!window.APP_IS_WHITE_LABEL ? (
				<div className="user-footer__content">
					<div className="user-footer__content__logo">
						{/* <img src={logo} alt="logo" /> */}
					</div>
					<div className="user-footer__content__text">
						<p className="powered-by">
							Powered by{" "}
							<a href="https://triviarat.com?utm_source=user">TriviaRat</a>
						</p>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default UserFooter;
