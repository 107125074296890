import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { authHostAPI } from "../../api/authHost-api";
import ReactGA from "react-ga4";

const GoogleAuth = ({
	onGoogleAuthSuccess,
	onGoogleAuthFail,
	onSetLoading,
	type,
}) => {
	let label = type || "signup_with";

	const handleSuccess = async (response) => {
		onSetLoading(true);
		console.log("g-auth signing up", response);
		const { credential, clientId, select_by } = response;

		if (type == "signup_with") {
			console.log("g-auth - signup_with");
			ReactGA.event({
				category: "Host Actions",
				action: "free_signup",
			});
		}
		try {
			const res = await authHostAPI.signUpGoogle(credential);
			console.log("g-response", res);
			//onGoogleAuthSuccess(res);
			loginGoogle(res);
		} catch (error) {
			console.log("g-error", error);
			onGoogleAuthFail(error);
		}
	};

	const handleFailure = (response) => {
		console.log("g-auth", response);
		onGoogleAuthFail(response);
	};

	//move signup.js signin.js here:
	const loginGoogle = async (response) => {
		try {
			localStorage.setItem(
				"user",
				JSON.stringify({
					role: response.role ?? "auth",
					auth: response.token,
					sso: response.sso || false,
				})
			);

			localStorage.removeItem("utm_source");

			ReactGA.event({
				category: "Host Actions",
				action: "google_auth",
			});

			//window.location.pathname = "/host/dashboard";

			onSetLoading(false);
			window.location.pathname = "/host/";
			window.scrollTo(0, 0);

			//gaEvent("free_signup", values.email);
		} catch (error) {
			console.log("error", error);
			onGoogleAuthFail("Error authenticating with Google");
			onSetLoading(false);
		}
	};

	const dummyCreds = {
		credential:
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDRiZTA4N2YwMTFiZjllODcyZmIzZWMiLCJlbWFpbCI6Im5pY2tkaWZlbGljZUBnbWFpbC5jb20iLCJyb2xlIjoiYXV0aCIsImlhdCI6MTcwOTczMjM5MiwiZXhwIjoxNzE3NTA4MzkyfQ.nPaft9faIuXaiheqMrntE8x-x_GHryNPJqXjqU21o0M",

		clientId:
			"720549866724-1csgnhshgbh0n79tkajh76n2kdiuadda.apps.googleusercontent.com",
		select_by: "btn",
	};

	return (
		<>
			<div className="subtitle text-center mb-2">OR</div>
			<div className="d-flex flex-column align-items-center marking">
				<GoogleLogin
					onSuccess={handleSuccess}
					onFailure={handleFailure}
					text={type}
				/>
			</div>
		</>
	);
};

export default GoogleAuth;
