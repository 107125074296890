import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { playerAPI } from "../../../../api/player-api";
import { compare } from "../../../../util/compare";

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e._id === item);
};

const PlayersList = ({
  playersFromSocket,
  playerToRemove,
  setPlayerToRemove,
  playerToAdd,
  setPlayerToAdd,
  manuallyUpdatedPlayer,
  setManuallyUpdatedPlayer,
  afterReconnect,
  setAfterReconnect,
  playerToUpdateName,
  setPlayerToUpdateName,
  playersWithBonuses,
  setPlayersWithBonuses,
  pauseModeContent,
  gamePauseMessage,
}) => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    if (playersWithBonuses) {
      const playersToSet = players;

      for (const playerWithBonus of playersWithBonuses) {
        for (const player of playersToSet) {
          if (player._id.toString() === playerWithBonus._id) {
            player.score = playerWithBonus.score;
            break;
          }
        }
      }

      setPlayersWithBonuses(undefined);
      setPlayers(playersToSet);
    }
  }, [players, playersWithBonuses, setPlayersWithBonuses]);

  useEffect(() => {
    if (playerToUpdateName) {
      const playersToSet = players;

      for (const player of playersToSet) {
        if (player._id.toString() === playerToUpdateName._id) {
          player.name = playerToUpdateName.name;
          break;
        }
      }
      setPlayerToUpdateName(undefined);
      setPlayers(playersToSet);
    }
  }, [playerToUpdateName, setPlayerToUpdateName, players]);

  useEffect(() => {
    if (manuallyUpdatedPlayer) {
      const playersToSet = players;

      for (const player of playersToSet) {
        if (player._id === manuallyUpdatedPlayer._id) {
          player.score = manuallyUpdatedPlayer.score;

          break;
        }
      }

      setManuallyUpdatedPlayer(undefined);
      setPlayers(playersToSet);
    }
  }, [manuallyUpdatedPlayer, players, setManuallyUpdatedPlayer]);

  useEffect(() => {
    if (playerToAdd) {
      const playersToSet = players;

      if (
        !playersToSet.find(
          (p) => p._id.toString() === playerToAdd._id.toString()
        )
      ) {
        playersToSet.push(playerToAdd);
      }

      setPlayerToAdd(undefined);
      setPlayers(playersToSet);
    }
  }, [playerToAdd, players, setPlayerToAdd]);

  useEffect(() => {
    if (playerToRemove) {
      const playersToSet = players;

      const index = getItemIndex(playersToSet, playerToRemove._id);

      if (index > -1) {
        playersToSet.splice(index, 1);
      }

      setPlayerToRemove(undefined);
      setPlayers(playersToSet);
    }
  }, [playerToRemove, players, setPlayerToRemove]);

  useEffect(() => {
    if (playersFromSocket && !afterReconnect) {
      setPlayers(playersFromSocket);
    } else {
      playerAPI.getGame().then((res) => {
        const plToSet = [];
        for (const player of res.game.players) {
          plToSet.push(player);
        }
        setPlayers(plToSet);
      });
    }
    setAfterReconnect(false);
  }, [playersFromSocket, afterReconnect, setAfterReconnect]);
  return (
    <>
      <div className="table-wrapper">
        {pauseModeContent !== "gamePauseMessage" ? (
          <Table striped className="varitykiller">
            <thead>
              <tr>
                <th>Player</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {players.sort(compare).map((player, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{player.name}</td>
                    <td>{player.score}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className="pause-game-message text-center">
            <h2>{gamePauseMessage}</h2>
          </div>
        )}
      </div>
    </>
  );
};

export default PlayersList;
