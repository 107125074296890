import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authHostAPI } from "../../api/authHost-api";
import openSocket from "socket.io-client";
import "./style.scss";
import { compare } from "../../util/compare";
import { socketUrl } from "../../util/constants";

let socket;
const createNewConnection = () => {
  socket = openSocket(socketUrl, { reconnection: false });
};
let savedId;

const getItemIndex = (arr, item) => {
  return arr.findIndex((e) => e._id === item);
};

const TwitchScoreTable = () => {
  const [players, setPlayers] = useState();
  const [haveAccess, setHaveAccess] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [playersToUpdate, setPlayersToUpdate] = useState(undefined); // undefined || array
  const [manuallyUpdatedPlayer, setManuallyUpdatedPlayer] = useState(undefined); // undefined || object
  const [playerToDelete, setPlayerToDelete] = useState(undefined); // undefined || object
  const [playerToAdd, setPlayerToAdd] = useState(undefined); // undefined || object
  const [playersWithBonuses, setPlayersWithBonuses] = useState(undefined); // undefined || array
  const [playerToUpdateName, setPlayerToUpdateName] = useState(undefined);
  const [adjustedPlayers, setAdjustedPlayers] = useState(undefined);
  const [wrongAnswers, setWrongAnswers] = useState(undefined);
  const { key } = useParams();

  const getScoreTable = useCallback(() => {
    authHostAPI
      .getScoreTable(key)
      .then((res) => {
        setPlayers(res.players);
        setRoomId(res.roomId);
        savedId = res.roomId;
        setHaveAccess(true);
      })
      .catch((err) => {
        if (err.response.statusCode === 403) {
          setHaveAccess(false);
        }
      });
  }, [key]);

  useEffect(() => {
    getScoreTable();
  }, [key, getScoreTable]);

  useEffect(() => {
    if (playersWithBonuses) {
      const playersToSet = players;

      for (const playerWithBonus of playersWithBonuses) {
        for (const player of playersToSet) {
          if (player._id.toString() === playerWithBonus._id) {
            player.score = playerWithBonus.score;
            break;
          }
        }
      }

      setPlayersWithBonuses(undefined);
      setPlayers(playersToSet);
    }
  }, [players, playersWithBonuses, setPlayersWithBonuses]);

  useEffect(() => {
    if (playersToUpdate) {
      const playersToSet = players;

      for (const playerToUpdate of playersToUpdate) {
        for (const player of playersToSet) {
          if (player._id === playerToUpdate._id) {
            player.score = playerToUpdate.score;

            break;
          }
        }
      }

      setPlayersToUpdate(undefined);
      setPlayers(playersToSet);
    }
  }, [players, playersToUpdate]);

  useEffect(() => {
    if (adjustedPlayers) {
      const playersToSet = players;

      for (const playerToUpdate of adjustedPlayers) {
        for (const player of playersToSet) {
          if (player._id === playerToUpdate._id) {
            player.score = playerToUpdate.score;

            break;
          }
        }
      }

      setAdjustedPlayers(undefined);
      setPlayers(playersToSet);
    }
  }, [players, adjustedPlayers]);

  useEffect(() => {
    if (wrongAnswers) {
      const playersToSet = players;

      for (const answer of wrongAnswers) {
        for (const updatedPlayer of answer.players) {
          for (const player of playersToSet) {
            if (player._id === updatedPlayer._id) {
              player.score = updatedPlayer.score;
              break;
            }
          }
        }
      }
      setWrongAnswers(undefined);
      setPlayers(playersToSet);
    }
  }, [players, wrongAnswers]);

  useEffect(() => {
    if (manuallyUpdatedPlayer) {
      const playersToSet = players;

      for (const player of playersToSet) {
        if (player._id === manuallyUpdatedPlayer._id) {
          player.score = manuallyUpdatedPlayer.score;

          break;
        }
      }

      setManuallyUpdatedPlayer(undefined);
      setPlayers(playersToSet);
    }
  }, [manuallyUpdatedPlayer, players]);

  useEffect(() => {
    if (playerToDelete) {
      const playersToSet = players;

      const index = getItemIndex(playersToSet, playerToDelete);

      if (index > -1) {
        playersToSet.splice(index, 1);
      }

      setPlayerToDelete(undefined);
      setPlayers(playersToSet);
    }
  }, [playerToDelete, players]);

  useEffect(() => {
    if (playerToAdd) {
      const playersToSet = players;

      if (
        !playersToSet.find(
          (p) => p._id.toString() === playerToAdd._id.toString()
        )
      ) {
        playersToSet.push(playerToAdd);
      }

      setPlayerToAdd(undefined);
      setPlayers(playersToSet);
    }
  }, [players, playerToAdd]);

  useEffect(() => {
    if (playerToUpdateName) {
      const playersToSet = players;

      for (const player of playersToSet) {
        if (player._id.toString() === playerToUpdateName._id) {
          player.name = playerToUpdateName.name;
          break;
        }
      }
      setPlayerToUpdateName(undefined);
      setPlayers(playersToSet);
    }
  }, [playerToUpdateName, players]);

  useEffect(() => {
    createNewConnection();
  }, []);

  const connectToSockets = useCallback((roomId) => {
    socket.emit("twitchBoardWS", roomId);
    socket.on("twitch", (data) => {
      if (data.action === "mark-answer") {
        setPlayersToUpdate(data.players);
      } else if (data.action === "manually-updated") {
        setManuallyUpdatedPlayer(data.player);
      } else if (data.action === "player-deleted") {
        setPlayerToDelete(data.player);
      } else if (data.action === "mark-incorrect") {
        setWrongAnswers(data.answers);
      } else if (data.action === "clear-scores") {
        setPlayersToUpdate(data.players);
      }
    });
    socket.on("delete", (data) => {
      if (data.action === "all") {
        setPlayers([]);
      }
    });
    socket.on("player", (data) => {
      if (data.action === "join") {
        setPlayerToAdd(data.player);
      } else if (data.action === "name-updated") {
        setPlayerToUpdateName(data.player);
      } else if (data.action === "adjusted-players") {
        setAdjustedPlayers(data.players);
      } else if (data.action === "bonus-points") {
        setPlayersWithBonuses(data.updatedPlayers);
      }
    });
  }, []);

  useEffect(() => {
    if (roomId.length) {
      connectToSockets(roomId);
    }
  }, [roomId, connectToSockets]);

  const tryReconnect = () => {
    setTimeout(() => {
      socket.io.open((err) => {
        if (err) {
          tryReconnect();
        } else {
          if (savedId) {
            createNewConnection();
            connectToSockets(savedId);
            getScoreTable();
          }
        }
      });
    }, 1000);
  };
  if (socket) {
    socket.io.on("close", () => {
      tryReconnect();
    });
  }

  return (
    <>
      {haveAccess ? (
        <>
          {players && (
            <div className="twitch-score-table">
              <table className=" ">
                <tbody>
                  {players
                    .sort(compare)
                    .slice(0, 10)
                    .map((player, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="pr-4">{player.name}</td>
                        <td>
                          <span>{player.score}</span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <h1>Twitch Integration disabled by host!</h1>
      )}
    </>
  );
};

export default TwitchScoreTable;
