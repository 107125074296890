import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayersList from "./PlayersList/PlayersList";
import Marking from "./Marking/Marking";
import EnterResponse from "./EnterResponse/EnterResponse";
import TipModal from "../../Admin/Modals/TipModal";
import { playerAPI } from "../../../api/player-api";
import openSocket from "socket.io-client";
import { Button } from "react-bootstrap";
import PlayerHeader from "../PlayerHeader/PlayerHeader";
import { Helmet } from "react-helmet";

import "./playerStyle.scss";
import { socketUrl } from "../../../util/constants";
import AdSense from "../../../components/AdSense/AdSense";

const userFromLS = JSON.parse(localStorage.getItem("user"));

let socket;
const createNewConnection = () => {
	socket = openSocket(socketUrl, { reconnection: false });
};
let savedId;
let savedPlayer;

const Player = () => {
	const [role, setRole] = useState("");
	const [player, setPlayer] = useState(null);
	const [gameMode, setGameMode] = useState("loading");
	const [roundMode, setRoundMode] = useState("");
	const [imageMode, setImageMode] = useState(false);
	const [showAnswers, setShowAnswers] = useState(false);
	const [showScores, setShowScores] = useState(false);
	const [displayCorrectAnswer, setDisplayCorrectAnswer] = useState(false);
	const [showQuestionsWS, setShowQuestionsWS] = useState(undefined);
	const [customTitle, setCustomTitle] = useState(String);
	const [gameComponent, setGameComponent] = useState(null);
	const [roomCode, setRoomCode] = useState(String);
	const [roundCompleteMessage, setRoundCompleteMessage] = useState(String);
	const [showCustomLink, setShowCustomLink] = useState(false);
	const [showTriviaMedia, setShowTriviaMedia] = useState(false);
	const [customUrlTitle, setCustomUrlTitle] = useState(String);
	const [customUrl, setCustomUrl] = useState(String);
	const [zoomUrl, setZoomUrl] = useState(String);
	const [showZoomBtn, setShowZoomBtn] = useState(false);
	const [twitchUrl, setTwitchUrl] = useState(String);
	const [showTwitchBtn, setShowTwitchBtn] = useState(false);
	const [gameId, setGameId] = useState(undefined);
	const [answersFromSocket, setAnswersFromSocket] = useState(undefined); // array || undefined
	const [currentRound, setCurrentRound] = useState([]);
	const [gameInfo, setGameInfo] = useState({});
	const [questionsFromSocket, setQuestionsFromSocket] = useState(undefined); // array || undefined
	const [playerAnswersFromSocket, setPlayerAnswersFromSocket] = useState([]);
	const [playersFromSocket, setPlayersFromSocket] = useState(undefined); // array || undefined
	const [manuallyUpdatedPlayer, setManuallyUpdatedPlayer] = useState(undefined); // undefined || object
	const [playersWithBonuses, setPlayersWithBonuses] = useState(undefined); // undefined || array
	const [pauseModeContent, setPauseModeContent] = useState(""); // scoreTable | gamePauseMessage
	const [gamePauseMessage, setGamePauseMessage] = useState("");
	const [deletedFromGame, setDeletedFromGame] = useState();
	const [modalContent, setModalContent] = useState("");
	const [updatedAnswer, setUpdatedAnswer] = useState();
	const [playerToRemove, setPlayerToRemove] = useState();
	const [playerToAdd, setPlayerToAdd] = useState(undefined);
	const [playerToUpdateName, setPlayerToUpdateName] = useState(undefined);
	const [playerToUpdateToken, setPlayerToUpdateToken] = useState(undefined);
	const [players, setPlayers] = useState(undefined);
	const [afterReconnect, setAfterReconnect] = useState(false);
	const [playerScore, setPlayerScore] = useState(0);
	const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
	const [requirePlayerEmail, setRequirePlayerEmail] = useState(false);
	const [adsenseKey, setAdsenseKey] = useState(99);
	const [lockedQuestion, setLockedQuestion] = useState();

	const getGame = () => {
		playerAPI
			.getGame()
			.then((res) => {
				for (const player of res.game.players) {
					if (player._id === userFromLS.ID) {
						setPlayerScore(player.score);
						break;
					}
				}
				setRoundMode(res.game.roundMode);
				setGameMode(res.game.gameMode);
				setRoomCode(res.game.roomCode);
				setShowCorrectAnswers(res.game.showCorrectAnswers);
				setDisplayCorrectAnswer(res.game.displayCorrectAnswer);
				setRequirePlayerEmail(res.game.requirePlayerEmail);
				setPlayer(res.playerName);
				savedPlayer = res.playerName;
				setShowAnswers(res.game.showAnswers);
				setShowScores(res.game.showScores);
				setRole(res.game.role);
				setLockedQuestion(res.game.lockQuestion ? res.game.lockedQuestion : undefined);
				if (res.game.role === "pro") {
					setDisplayCorrectAnswer(res.game.displayCorrectAnswer);
					setCustomTitle(res.game.customTitle);
					setRoundCompleteMessage(res.game.roundCompleteMessage);
					if (res.game.twitchUrl) {
						setShowTwitchBtn(true);
						setTwitchUrl(res.game.twitchUrl);
					}
					if (res.game.zoomUrl) {
						setShowZoomBtn(true);
						setZoomUrl(res.game.zoomUrl);
					}
					if (res.game.customUrl) {
						setShowCustomLink(true);
						setCustomUrl(res.game.customUrl);
						setCustomUrlTitle(res.game.customUrlTitle);
					}
					setPauseModeContent(res.game.pauseModeContent);
					setGamePauseMessage(res.game.gamePauseMessage);
				} else if (res.game.role === "auth") {
					setShowCustomLink(true);
					setCustomUrl("https://www.triviarat.com");
					setCustomUrlTitle("Powered By Triviarat");
					setShowTriviaMedia(true);
				}
				setGameId(res.game._id);
				savedId = res.game._id;
			})
			.catch((err) => {
				if (err.response && err.response.status === 430) {
					localStorage.removeItem("user");
					window.location.pathname = "/";
				} else {
					alert(err);
				}
			});
	};

	useEffect(() => {
		createNewConnection();
		getGame();
	}, []);

	useEffect(() => {
		if (players) {
			for (const player of players) {
				if (player._id === userFromLS.ID) {
					setPlayerScore(player.score);
					break;
				}
			}
		}
		setPlayers(undefined);
	}, [players]);

	useEffect(() => {
		if (playerToUpdateToken) {
			setPlayer(playerToUpdateToken.name);
			localStorage.setItem(
				"user",
				JSON.stringify({
					role: "player",
					auth: playerToUpdateToken.token,
					room: roomCode,
					ID: playerToUpdateToken._id,
				})
			);
		}
		setPlayerToUpdateToken(undefined);
	}, [playerToUpdateToken, roomCode]);

	let existingTwitch = showTwitchBtn ? "zoom-btn mr-2" : "zoom-btn";

	useEffect(() => {
		switch (gameMode) {
			case "pause":
				setGameComponent(
					<PlayersList
						playerToRemove={playerToRemove}
						playersFromSocket={playersFromSocket}
						setPlayerToRemove={setPlayerToRemove}
						playerToAdd={playerToAdd}
						setPlayerToAdd={setPlayerToAdd}
						manuallyUpdatedPlayer={manuallyUpdatedPlayer}
						setManuallyUpdatedPlayer={setManuallyUpdatedPlayer}
						afterReconnect={afterReconnect}
						setAfterReconnect={setAfterReconnect}
						playerToUpdateName={playerToUpdateName}
						setPlayerToUpdateName={setPlayerToUpdateName}
						playersWithBonuses={playersWithBonuses}
						setPlayersWithBonuses={setPlayersWithBonuses}
						pauseModeContent={pauseModeContent}
						gamePauseMessage={gamePauseMessage}
					/>
				);
				break;
			case "play":
				setGameComponent(
					<EnterResponse
						role={role}
						roundCompleteMessage={roundCompleteMessage}
						setCustomTitle={setCustomTitle}
						currentRoundFromSocket={currentRound}
						gameInfo={gameInfo}
						questionsFromSocket={questionsFromSocket}
						setQuestionsFromSocket={setQuestionsFromSocket}
						playerAnswersFromSocket={playerAnswersFromSocket}
						setImageMode={setImageMode}
						imageMode={imageMode}
						afterReconnect={afterReconnect}
						setAfterReconnect={setAfterReconnect}
						showQuestionsWS={showQuestionsWS}
						playerId={userFromLS.ID}
						playerScore={playerScore}
						setAdsenseKey={setAdsenseKey}
						lockedQuestion={lockedQuestion}
					/>
				);

				break;
			case "check":
				setGameComponent(
					<Marking
						answersFromSocket={answersFromSocket}
						showAnswers={showAnswers}
						showCorrectAnswers={showCorrectAnswers}
						displayCorrectAnswer={displayCorrectAnswer}
						roundMode={roundMode}
						updatedAnswer={updatedAnswer}
						setUpdatedAnswer={setUpdatedAnswer}
						afterReconnect={afterReconnect}
						setAfterReconnect={setAfterReconnect}
					/>
				);
				break;
			default:
				setGameComponent(null);
				break;
		}
	}, [
		role,
		currentRound,
		gameInfo,
		imageMode,
		showAnswers,
		gameMode,
		displayCorrectAnswer,
		roundCompleteMessage,
		questionsFromSocket,
		answersFromSocket,
		playerAnswersFromSocket,
		playersFromSocket,
		updatedAnswer,
		playerToRemove,
		playerToAdd,
		showQuestionsWS,
		manuallyUpdatedPlayer,
		afterReconnect,
		playerToUpdateName,
		playerScore,
		playersWithBonuses,
		pauseModeContent,
		gamePauseMessage,
		lockedQuestion
	]);

	const connectToSockets = useCallback((gameId, userId, player) => {
		socket.emit("playerWS", userId);
		socket.emit("room", gameId);
		socket.on("mode", (data) => {
			if (data.action === "player-check") {
				setAnswersFromSocket(data.playerAnswers);
				setGameMode(data.mode);
			} else if (data.action === "check") {
				setGameMode(data.action);
			} else if (data.action === "player-play") {
				setPlayers(data.players);
				setPlayerAnswersFromSocket(data.playerAnswers);
				setCurrentRound(data.currentRound);
				setQuestionsFromSocket(data.questions);
				setGameInfo(data.gameInfo);
				setGameMode(data.mode);
				setQuestionsFromSocket(undefined);
			} else if (data.action === "play") {
				setPlayers(data.players);
				setQuestionsFromSocket(undefined);
				setPlayerAnswersFromSocket([]);
				setCurrentRound(data.currentRound);
				setQuestionsFromSocket(data.questions);
				setGameInfo(data.gameInfo);
				setGameMode(data.mode);
			} else if (data.action === "pause") {
				setPlayersFromSocket(data.players);
				setGameMode(data.mode);
			} else if (data.action === "new-round") {
				setGameMode(data.mode);
			}
		});
		socket.on("player", (data) => {
			if (data.action === "join") {
				setPlayerToAdd(data.player);
			} else if (data.action === "manually-updated") {
				setManuallyUpdatedPlayer(data.player);
			} else if (data.action === "name-updated") {
				setPlayerToUpdateName(data.player);
			} else if (data.action === "player-info") {
				setPlayerToUpdateToken(data.player);
			} else if (data.action === "clear-scores") {
				setAnswersFromSocket(data.playerAnswers);
				setPlayersFromSocket(data.players);
				setPlayerScore(data.player.score);
			} else if (data.action === "score-updated") {
				setPlayerScore(data.score);
			} else if (data.action === "bonus-points") {
				setPlayersWithBonuses(data.updatedPlayers);
			}
		});

		socket.on("mark", (data) => {
			setUpdatedAnswer(data.answer);
			setPlayerScore(data.score);
		});

		socket.on("delete", (data) => {
			if (data.action === "all") {
				setModalContent("You have been removed from the game!");
				setDeletedFromGame(true);
				localStorage.removeItem("user");
			} else if (data.action === "player") {
				if (data.player.name.toString() === player) {
					setModalContent("You have been removed from the game!");
					setDeletedFromGame(true);
					localStorage.removeItem("user");
				} else {
					setPlayerToRemove(data.player);
				}
			} else if (data.action === "room") {
				setModalContent("Guest room was deleted");
				setDeletedFromGame(true);
				localStorage.removeItem("user");
			}
		});

		socket.on("settings", (data) => {
			if (data.action === "change-room-code") {
				setRoomCode(data.roomCode);
			} else if (data.action === "update-settings") {
				setShowAnswers(data.showAnswers);
				setShowScores(data.showScores);
				getGame();
			} else if (data.action === "change-image-mode") {
				setImageMode(data.imageMode);
			} else if (data.action === "update-pro-game-settings") {
				setShowQuestionsWS(data.showQuestions);
				setPauseModeContent(data.pauseModeContent);
				setGamePauseMessage(data.gamePauseMessage);
				getGame();
			} else if (data.action === "show-scores") {
				setShowScores(data.showScores);
			} else if (data.action === "update-locked-question") {
				getGame();
			}
		});
	}, []);

	useEffect(() => {
		if (gameId && gameId.length && userFromLS.ID.length && player) {
			connectToSockets(gameId, userFromLS.ID, player);
		}
	}, [connectToSockets, gameId, player]);

	const tryReconnect = () => {
		setTimeout(() => {
			socket.io.open((err) => {
				if (err) {
					tryReconnect();
				} else {
					if (savedId.length && userFromLS.ID.length && savedPlayer) {
						createNewConnection();
						connectToSockets(savedId, userFromLS.ID, savedPlayer);
						setGameInfo({});
						setAfterReconnect(true);
						getGame();
					}
				}
			});
		}, 1000);
	};
	if (socket) {
		socket.io.on("close", () => {
			tryReconnect();
		});
	}

	const closeTipModal = () => {
		setDeletedFromGame(false);
		window.location.pathname = "/";
	};

	return (
		<div className={`player-mode ${!!role && role !== "pro" && "with-ad"}`}>
			<Helmet>
				<title>Trivia Rat</title>
			</Helmet>
			<PlayerHeader roomCode={roomCode} />
			<div className="board-wrapper">
				<div className="w-100">
					<div className="main-board d-flex flex-column align-items-center">
						<h5>Welcome {player}</h5>
						{showScores && gameMode !== "pause" && (
							<h4>Your score: {playerScore}</h4>
						)}
						<h2 className="text-center">{customTitle}</h2>
					</div>
					{gameComponent}
				</div>
				<div className="media-buttons">
					{showCustomLink ? (
						<div className="custom-url">
							<a
								rel="noopener noreferrer"
								href={customUrl}
								target="_blank"
								className="customBtn"
							>
								<Button variant="secondary">
									{showTriviaMedia ? (
										<FontAwesomeIcon icon="bolt" className="mr-2" />
									) : null}
									{customUrlTitle}
								</Button>
							</a>
						</div>
					) : null}
					<div className="social-medias">
						{showTriviaMedia ? (
							<>
								<a
									href="https://www.facebook.com/triviarat"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="mr-4 trivia-media">
										<FontAwesomeIcon icon={["fab", "facebook-square"]} />
									</div>
								</a>
								<a
									rel="noopener noreferrer"
									href="https://www.instagram.com/triviarat/"
									target="_blank"
								>
									<div className="trivia-media">
										<FontAwesomeIcon icon={["fab", "instagram"]} />
									</div>
								</a>
							</>
						) : null}
						{showZoomBtn ? (
							<a rel="noopener noreferrer" href={zoomUrl} target="_blank">
								<div className={existingTwitch}>
									<FontAwesomeIcon icon="video" />
								</div>
							</a>
						) : null}
						{showTwitchBtn ? (
							<a
								rel="noopener noreferrer"
								href={twitchUrl}
								className="twitch-btn"
								target="_blank"
							>
								<FontAwesomeIcon icon={["fab", "twitch"]} className="mr-1" />
							</a>
						) : null}
					</div>
				</div>
			</div>
			<TipModal show={deletedFromGame} handleClose={closeTipModal}>
				<h2 className="text-center">{modalContent}</h2>
			</TipModal>
			{!!role && role !== "pro" && (
				<div>
					<AdSense datakey={`adsenseKey_${adsenseKey}`} />
				</div>
			)}
		</div>
	);
};

export default Player;
