import { createContext } from "react";
import { observable, action } from "mobx";

class GameStore {
	@observable roomCode = String;
	@observable gameMode = String;
	@observable roundMode = String;
	@observable roundName = String;
	@observable email = String;
	@observable id = String;
	@observable role = String;
	@observable imageMode = Boolean;
	@observable helperTips = Boolean;
	@observable playersLimit = Number;
	@observable halfPoints = Boolean;
	@observable players = [];
	@observable round = [];
	@observable images = [];
	@observable scoringInterval = Number;
	@observable questionsPerRound = Number;
	@observable markedAnswers = Number;
	@observable totalAnswers = Number;
	@observable showAnswers = Boolean;
	@observable showScores = Boolean;
	@observable showCorrectAnswers = Boolean;
	@observable requirePlayerEmail = Boolean;
	@observable lockQuestion = false;
	@observable lockedQuestion = 0;
	@observable showQuestions = Boolean;
	@observable displayCorrectAnswer = Boolean;
	@observable adjustByResponse = Boolean;
	@observable switchRounds = Boolean;
	@observable adjustByQuestion = Boolean;
	@observable pauseModeContent = String;
	@observable gamePauseMessage = String;
	@observable twitchIntegration = Boolean;
	@observable apiIntegration = Boolean;
	@observable scoreTableKey = String;
	@observable apiSecret = String;
	@observable maxPointValue = Number;
	@observable customTitle = String;
	@observable customUrl = String;
	@observable customUrlTitle = String;
	@observable roundCompleteMessage = String;
	@observable zoomUrl = String;
	@observable twitchUrl = String;
	@observable wagerMode = Boolean;
	@observable sponsoredByAdmin = Boolean;

	@action
	setRound(newRound) {
		this.round.clear();
		newRound.forEach((question) => this.round.push(question));
	}

	@action
	updateRound(updatedAnswer, markedPoint) {
		for (let i = 0; i < this.round.length; i++) {
			if (this.round[i].answers && this.round[i].answers.length > 0) {
				if (
					this.round[i]._id === updatedAnswer.questionId._id ||
					this.round[i]._id === updatedAnswer.questionId
				) {
					for (let j = 0; j < this.round[i].answers.length; j++) {
						if (this.round[i].answers[j]._id === updatedAnswer._id) {
							this.round[i].answers[j].point = updatedAnswer.point;
							this.round[i].answers[j].halfPoint = updatedAnswer.halfPoint;
							this.round[i].answers[j].text = updatedAnswer.text;
							this.round[i].answers[j].marked = updatedAnswer.marked;
							markedPoint
								? (this.round[i].markedPoint = markedPoint)
								: delete this.round[i].markedPoint;
						}
					}
				}
			}
		}
		return this.round;
	}

	@action
	updateQuestion(question) {
		const index = this.round.findIndex((e) => e._id === question._id);

		if (index !== -1) {
			this.round[index] = question;
		} else {
			this.round.push(question);
		}

		if (this.totalAnswers === 0) {
			let totalAnswers = 0;
			this.round.forEach((question) => {
				question.answers.forEach((answer) => {
					totalAnswers++;
				});
			});
			this.totalAnswers = totalAnswers;
		}
	}

	@action
	setImages(images) {
		this.images.clear();
		images.forEach((img) => this.images.push(img));
	}

	@action
	setPlayers(players) {
		this.players.clear();
		players.forEach((player) => this.players.push(player));
	}

	@action
	upgradePlayers(updatedPlayers) {
		let newPlayers = [];
		let playersId = [];
		updatedPlayers.forEach((updPlayer) => {
			playersId.push(updPlayer._id);
		});

		this.players.forEach((player) => {
			if (playersId.indexOf(player._id) > -1) {
				updatedPlayers.forEach((updPlayer) => {
					if (player._id === updPlayer._id) {
						let playerToPush = {
							name: updPlayer.name,
							score: updPlayer.score,
							_id: updPlayer._id,
						};
						newPlayers.push(playerToPush);
					}
				});
			} else {
				newPlayers.push(player);
			}
		});

		this.players.clear();
		newPlayers.forEach((player) => this.players.push(player));
	}

	@action
	addQuestion(question) {
		for (let i in this.questions) {
			if (this.questions[i]._id.toString() !== question._id.toString()) {
				this.round.push(question);
			}
		}
	}

	@action
	addPlayer(player) {
		this.players.push(player);
	}

	@action
	updatePlayer(player) {
		for (let i in this.players) {
			if (this.players[i]._id.toString() === player._id.toString()) {
				this.players[i].score = player.score;
				break;
			}
		}
	}

	@action
	changePlayerName(player) {
		for (const playerToChange of this.players) {
			if (playerToChange._id === player._id) {
				playerToChange.name = player.name;
				break;
			}
		}

		for (const question of this.round) {
			answerLoop: for (const answer of question.answers) {
				for (const playerToChange of answer.players) {
					if (playerToChange._id === player._id) {
						playerToChange.name = player.name;
						break answerLoop;
					}
				}
			}
		}
	}

	@action
	updatePlayers() {
		this.players.forEach((player) => (player.score = 0));
	}

	@action
	removePlayer(player) {
		this.players.remove(player);
	}

	@action
	removePlayers() {
		this.players.clear();
	}

	@action
	setScoreTableKey(key) {
		this.scoreTableKey = key;
	}

	@action
	setApiSecret(key) {
		this.apiSecret = key;
	}

	@action
	endGame() {
		this.round.clear();
		this.markedAnswers = 0;
		this.totalAnswers = 0;
	}

	@action
	setWagerMode(wagerMode) {
		this.wagerMode = wagerMode;
	}

	@action
	updateSettings(res) {
		this.helperTips = res.helperTips;
		this.halfPoints = res.halfPoints;
		this.questionsPerRound = res.questionsPerRound;
		this.scoringInterval = res.scoringInterval;
		this.playersLimit = res.playersLimit;
		this.showAnswers = res.showAnswers;
		this.showScores = res.showScores;
		this.showCorrectAnswers = res.showCorrectAnswers;
		this.requirePlayerEmail = res.requirePlayerEmail;
		this.lockQuestion = res.lockQuestion;
		this.lockedQuestion = res.lockedQuestion;
	}

	@action
	updateCustomSettings(res) {
		this.customTitle = res.customTitle;
		this.customUrl = res.customUrl;
		this.customUrlTitle = res.customUrlTitle;
		this.roundCompleteMessage = res.roundCompleteMessage;
		this.zoomUrl = res.zoomUrl;
		this.twitchUrl = res.twitchUrl;
	}

	@action
	updateProOptions(res) {
		this.adjustByResponse = res.adjustByResponse;
		this.adjustByQuestion = res.adjustByQuestion;
		this.maxPointValue = res.maxPointValue;
		this.showQuestions = res.showQuestions;
		this.pauseModeContent = res.pauseModeContent;
		this.gamePauseMessage = res.gamePauseMessage;
		this.switchRounds = res.switchRounds;
	}

	@action
	clearAll() {
		this.players.clear();
		this.images.clear();
		this.round.clear();
		this.roomCode = String;
		this.gameMode = String;
		this.email = String;
		this.id = String;
		this.role = String;
		this.imageMode = Boolean;
		this.helperTips = Boolean;
		this.showAnswers = Boolean;
		this.showScores = Boolean;
		this.showCorrectAnswers = Boolean;
		this.requirePlayerEmail = Boolean;
		this.lockQuestion = false;
		this.lockedQuestion = Number;
		this.halfPoints = Boolean;
		this.scoringInterval = Number;
		this.questionsPerRound = Number;
		this.markedAnswers = Number;
		this.totalAnswers = Number;
	}
}

export const GameStoreContext = createContext(new GameStore());
