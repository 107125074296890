import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div class="snippet" data-title="dot-pulse">
      <div class="stage">
        <div class="dot-pulse"></div>
      </div>
    </div>
  );
};

export default Loader;
