import React from "react";
import { Redirect, Route } from "react-router-dom";
import Room from "../../pages/Room/Room";

const RoomRoute = ({ user, location, ...rest }) => {
	const url = location.pathname.split("/");
	if (user.role === "user" || (!user.role && url.length <= 2)) {
		return <Route component={Room} {...rest} />;
	} else if (!user.role && url.length > 2) {
		return <Redirect to={"/" + url[1]} />;
	} else if (user.role === "player") {
		return <Redirect to="/player" />;
	} else if (
		user.role === "auth" ||
		user.role === "guest" ||
		user.role === "pro"
	) {
		return <Redirect to="/host" />;
	} else {
		window.location.pathname = "/";
	}
};

export default RoomRoute;
