import React, { useContext, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";

import { userHostApi } from "../../../api/user-api";
import UserFooter from "../Components/UserFooter";

import { useFormik } from "formik";
import * as Yup from "yup";
import "../../formStyles.scss";
import "../user.scss";

import Rat from "../../../assets/images/triviarat-sign-up.png";
import useQuery from "../../../util/getQuery";

const initialValues = {
	email: "",
	password: "",
};

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.trim()
		.min(4, "Minimum 4 symbols")
		.required("Email is required"),
	password: Yup.string()
		.trim()
		.min(4, "Minimum 4 Characters")
		// .max(20, "Maximum 20 symbols")
		.required("Password is required"),
});

const SignIn = () => {
	const history = useHistory();
	const query = useQuery();
	const room = query.get("room");

	const [customRoom, setCustomRoom] = React.useState("");

	//check if room variable is set when component mounted, useEffect
	useEffect(() => {
		if (room) {
			localStorage.setItem("user_room", room);
			return;
		}
		console.log("Room is not set");
		//history.push("/");
	}, [room]);

	const navRoom = () => {
		history.push(`/user/sign-in?room=${customRoom}`);
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values, { setFieldError, setSubmitting }) => {
			setSubmitting(true);
			userHostApi
				.login(values.email.toLowerCase(), values.password)
				.then((res) => {
					if (res.verified == false) {
						history.push(`/user/resend-verification?room=${room ?? ""}`);
					} else {
						localStorage.setItem(
							"user",
							JSON.stringify({
								role: res.role,
								auth: res.token,
							})
						);

						if (room) {
							history.push(`/${room}`);
						} else {
							history.push("/");
						}
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 403) {
						setFieldError("email", err.response?.data?.message);
					} else if (err.response?.status === 409) {
						setFieldError(
							err.response?.data?.param,
							err.response?.data?.message
						);
					} else {
						alert(err);
					}
				});
		},
	});

	return (
		<div className="sign-in">
			<Helmet>
				<title>{window.APP_NAME} - Signin</title>
			</Helmet>
			{window.APP_IS_WHITE_LABEL ? (
				<div className="d-flex justify-content-center mb-3 m-2 p-2">
					<img
						className="white-label-image"
						src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLVddAc5uo-e3pZIoIjCpxr1LYee5zXR0Zs6NtrOFibJxuem2uGSQg3Rt82KZiHWLqzw&usqp=CAU"
					/>
				</div>
			) : null}

			<div className="d-flex justify-content-center flex-column align-center text-center my-4">
				<p className="overline">ROOM</p>
				<h4 className="thick">{room}</h4>
			</div>

			{room ? (
				<Form className="form" onSubmit={formik.handleSubmit}>
					<div className="d-flex justify-content-center mb-1">
						<h5>{window.APP_IS_WHITE_LABEL ? "Team" : "User"} Sign In</h5>
					</div>
					<p className="subtitle">
						Log in to access your account and continue your participation in
						games across different rooms. If you do not have an account, please{" "}
						<Link to={room ? `/user/sign-up?room=${room}` : "/user/sign-up"}>
							click here
						</Link>
						.
					</p>
					<Form.Group controlId="formBasicLogin">
						<Form.Control
							type="text"
							placeholder="Enter email"
							name="email"
							control="input"
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							required
							className="login-input"
						/>
						{formik.touched.email && !!formik.errors.email && (
							<p className="error">{formik.errors.email}</p>
						)}
					</Form.Group>

					<Form.Group controlId="formBasicPassword">
						<Form.Control
							type="password"
							placeholder="Password"
							name="password"
							onChange={formik.handleChange}
							value={formik.values.password}
							onBlur={formik.handleBlur}
							required
							className="login-input"
						/>
						{formik.touched.password && !!formik.errors.password && (
							<p className="error">{formik.errors.password}</p>
						)}
						<a href="/user/reset-password" className="forgot-btn float-right">
							Forgot Password?
						</a>
					</Form.Group>
					<Button
						variant="success"
						className="tiger sign-in-btn"
						block
						type="submit"
						disabled={formik.isSubmitting}
					>
						LOGIN!
					</Button>
					<p className="text-center message">
						Not registered?{" "}
						<Link to={room ? `/user/sign-up?room=${room}` : "/user/sign-up"}>
							Create an account
						</Link>
					</p>
					<div className="d-flex justify-content-center">
						<a href="/">
							<Button className="tiger my-4 home-button" variant="secondary">
								Home
							</Button>
						</a>
					</div>
				</Form>
			) : (
				<div className="d-flex flex-column align-items-center justify-content-center mb-1 mt-4 card shadow-lg p-4">
					<div className="alert alert-danger" role="alert">
						<h5>Room not set</h5>
					</div>
					<p>Please enter a room code</p>

					<Form.Group controlId="formBasicRoom">
						<Form.Control
							type="text"
							placeholder="Enter room code"
							name="room"
							onChange={(e) => setCustomRoom(e.target.value)}
							value={customRoom}
							required
							className="login-input mb-3 form-control-lg"
						/>
						<Button
							variant="success"
							className="tiger sign-in-btn"
							block
							onClick={navRoom}
							disabled={customRoom.length < 2}
						>
							ENTER
						</Button>
					</Form.Group>
				</div>
			)}

			<UserFooter />
		</div>
	);
};

export default SignIn;
